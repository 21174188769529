import { Component, inject, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { FullScreenModalConfig, MobileMaxWidth, ORGANIZATION_AVERAGE } from '@mg-platform/core/core-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { IShop, ShopsState } from '@mg-platform/shops/shops-data-access'
import { IncludedShopsDialogComponent } from '../included-shops-dialog/included-shops-dialog.component'
import { Observable } from 'rxjs'
import { FiltersState } from '@mg-platform/core/core-data-access'

@UntilDestroy()
@Component({
  selector: 'mg-included-shops-button',
  templateUrl: './included-shops-button.component.html',
  styleUrls: ['./included-shops-button.component.scss']
})
export class IncludedShopsButtonComponent implements OnInit {
  @Input() shopIds: string[] = []
  shopsList: IShop[] = []

  shop$: Observable<IShop> = inject(Store).select(ShopsState.shop)
  shopId: string
  groupId$: Observable<string> = inject(Store).select(FiltersState.groupId)
  groupId?: string
  organizationAverageId = ORGANIZATION_AVERAGE.id

  window = window
  mobileMaxWidth = MobileMaxWidth

  constructor(
    private dialogService: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    const organizationShops = this.store.selectSnapshot(ShopsState.organizationShops) ?? []
    this.shopsList =
      (this.shopIds
        ?.map((el) => organizationShops.find((shop) => shop.id === el))
        ?.filter((el) => !!el) as IShop[]) ?? []

    this.shop$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.shopId = res?.id
    })

    this.groupId$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.groupId = res
    })
  }

  openDialog() {
    this.dialogService.open(IncludedShopsDialogComponent, {
      ...FullScreenModalConfig,
      data: {
        shopsList: this.shopsList
      }
    })
  }
}
