import { IExtendedRequest } from '@mg-platform/core/core-data-access'

export class GetShopBenchmark {
  static readonly type = '[BenchmarkReports] Get Shop Benchmark'
  constructor(public payload: IExtendedRequest) {}
}

export class GetCalendarBenchmark {
  static readonly type = '[BenchmarkReports] Get Calendar Benchmark'
  constructor(public shopId: string) {}
}

export class ResetBenchmark {
  static readonly type = '[BenchmarkReports] Reset Benchmark'
}
