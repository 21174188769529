import { environment } from '@mg-platform/core/core-util'

export const ENTERPRISE_REPORTS_API_URL = {
  // Benchmarks Reports
  benchmarks: `${environment.apiUrl}/benchmarks/enterprise`,

  // Trends Reports
  trends: `${environment.apiUrl}/trends/enterprise`,

  // Market Pricings Reports
  marketPricings: `${environment.apiUrl}/marketpricings/enterprise`
}
