import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { groupBy, max, min } from 'lodash'
import { tap } from 'rxjs/operators'
import { ITrendItem } from '../../interfaces/trends.interface'
import { ReportsService } from '../../reports.service'
import {
  DeleteTireTrendsInterval,
  DeleteTrendsInterval,
  GetTireTrends,
  GetTrends,
  UpdateTireTrendsInterval,
  UpdateTrendsInterval
} from './trend-reports.actions'
import { TrendReportsStateModel } from './trend-reports.model'
import {
  IAverageTrendResponse,
  IAverageTrendResponseExtended
} from '../../interfaces/average-trends.interface'
import { TrendIntervalType } from '../../enums/trend-interval-type.enum'
import {
  IAverageTireTrendsResponse,
  IAverageTireTrendsResponseExtended
} from '../../interfaces/average-tire-trends.interface'

@State<TrendReportsStateModel>({
  name: 'trendReports'
})
@Injectable()
export class TrendReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static trends(state: TrendReportsStateModel): IAverageTrendResponse {
    return state.trends
  }

  @Selector()
  static trendIntervalLabels(state: TrendReportsStateModel) {
    return state.trends?.trendIntervalLabels
  }

  @Selector()
  static groupByTrends(state: TrendReportsStateModel): Partial<IAverageTrendResponseExtended> {
    const result: Partial<IAverageTrendResponseExtended> = {}

    const summaries = (state?.trends?.averageTrendSummaries ?? []).map((el) => ({
      trendIntervalType: el.trendIntervalType,
      sales: {
        ...el.sales,
        id: el.id,
        displayText: el.displayText,
        marketType: el.marketType,
        countryId: el.countryId
      },
      cars: {
        ...el.cars,
        id: el.id,
        displayText: el.displayText,
        marketType: el.marketType,
        countryId: el.countryId
      },
      averageTicket: {
        ...el.averageTicket,
        id: el.id,
        displayText: el.displayText,
        marketType: el.marketType,
        countryId: el.countryId
      }
    }))

    const summariesGroupByInterval = groupBy(summaries, (el) => el.trendIntervalType)
    if (summariesGroupByInterval[TrendIntervalType.FourWeeks]) {
      const data = summariesGroupByInterval[TrendIntervalType.FourWeeks]
      result.fourWeeks = {
        sales: data.map((el) => el.sales),
        cars: data.map((el) => el.cars),
        averageTicket: data.map((el) => el.averageTicket)
      }
    }

    if (summariesGroupByInterval[TrendIntervalType.EightWeeks]) {
      const data = summariesGroupByInterval[TrendIntervalType.EightWeeks]
      result.eightWeeks = {
        sales: data.map((el) => el.sales),
        cars: data.map((el) => el.cars),
        averageTicket: data.map((el) => el.averageTicket)
      }
    }

    if (summariesGroupByInterval[TrendIntervalType.Custom]) {
      const data = summariesGroupByInterval[TrendIntervalType.Custom]
      result.customWeeks = {
        sales: data.map((el) => el.sales),
        cars: data.map((el) => el.cars),
        averageTicket: data.map((el) => el.averageTicket)
      }
    }

    return { ...result, trendsCustomInterval: state.trends?.trendsCustomInterval }
  }

  @Selector()
  static tireTrends(state: TrendReportsStateModel): IAverageTireTrendsResponse {
    return state.tireTrends
  }

  @Selector()
  static tireTrendIntervalLabels(state: TrendReportsStateModel) {
    return state.tireTrends?.trendIntervalLabels
  }

  @Selector()
  static groupByTireTrends(state: TrendReportsStateModel): Partial<IAverageTireTrendsResponseExtended> {
    const result: Partial<IAverageTireTrendsResponseExtended> = {}

    const summaries = (state?.tireTrends?.averageTireTrendSummaries ?? []).map((el) => ({
      trendIntervalType: el.trendIntervalType,
      tireSales: {
        ...el.tireSales,
        id: el.id,
        displayText: el.displayText,
        marketType: el.marketType,
        countryId: el.countryId
      }
    }))

    const summariesGroupByInterval = groupBy(summaries, (el) => el.trendIntervalType)
    if (summariesGroupByInterval[TrendIntervalType.FourWeeks]) {
      const data = summariesGroupByInterval[TrendIntervalType.FourWeeks]
      result.fourWeeks = {
        tireSales: data.map((el) => el.tireSales)
      }
    }

    if (summariesGroupByInterval[TrendIntervalType.EightWeeks]) {
      const data = summariesGroupByInterval[TrendIntervalType.EightWeeks]
      result.eightWeeks = {
        tireSales: data.map((el) => el.tireSales)
      }
    }

    if (summariesGroupByInterval[TrendIntervalType.Custom]) {
      const data = summariesGroupByInterval[TrendIntervalType.Custom]
      result.customWeeks = {
        tireSales: data.map((el) => el.tireSales)
      }
    }

    return { ...result, trendsCustomInterval: state.tireTrends?.trendsCustomInterval }
  }

  @Action(GetTrends, { cancelUncompleted: true })
  getTrends(ctx: StateContext<TrendReportsStateModel>, { payload }: GetTrends) {
    return this.reportsService.getTrends(payload).pipe(
      tap((res) => {
        ctx.patchState({
          trends: res
        })
      })
    )
  }

  @Action(UpdateTrendsInterval)
  updateTrendsInterval(
    ctx: StateContext<TrendReportsStateModel>,
    { trendsCustomInterval }: UpdateTrendsInterval
  ) {
    return this.reportsService.updateTrendsInterval(trendsCustomInterval)
  }

  @Action(DeleteTrendsInterval)
  deleteTrendsInterval() {
    return this.reportsService.deleteTrendsInterval()
  }

  @Action(GetTireTrends, { cancelUncompleted: true })
  getTireTrends(ctx: StateContext<TrendReportsStateModel>, { payload }: GetTireTrends) {
    return this.reportsService.getTireTrends(payload).pipe(
      tap((res) => {
        ctx.patchState({
          tireTrends: res
        })
      })
    )
  }

  @Action(UpdateTireTrendsInterval)
  updateTireTrendsInterval(
    ctx: StateContext<TrendReportsStateModel>,
    { trendsCustomInterval }: UpdateTireTrendsInterval
  ) {
    return this.reportsService.updateTireTrendsInterval(trendsCustomInterval)
  }

  @Action(DeleteTireTrendsInterval)
  deleteTireTrendsInterval() {
    return this.reportsService.deleteTireTrendsInterval()
  }

  public static addGraphMinMax(item: ITrendItem): ITrendItem {
    const allData = item.graphs
      .map((g) => [
        ...g.translatedTrend.map((s) => [s.shop, s.market]),
        ...g.shop.map((s) => [s.data, s.trend]),
        ...g.market.map((m) => [m.data, m.trend])
      ])
      .flat(2)

    return {
      ...item,
      range: {
        min: min(allData) ?? 0,
        max: max(allData) ?? 0
      }
    }
  }
}
