import { environment } from '@mg-platform/core/core-util'

export const REPORTS_API_URL = {
  // Benchmark Reports
  benchmarks: `${environment.apiUrl}/benchmarks`,
  calendar: (shopId: string) => `${environment.apiUrl}/benchmarks/${shopId}/calendar`,

  // Daily Reports
  getOneDailyReport: (shopId: string, dailyReportId: string) =>
    `${environment.apiUrl}/dailyreports/${shopId}/${dailyReportId}`,
  addDailyReport: `${environment.apiUrl}/dailyreports`,
  updateDailyReport: (shopId: string) => `${environment.apiUrl}/dailyreports/${shopId}`,
  getMissingDaysReports: `${environment.apiUrl}/dailyreports/getmissingdaysreports`,

  // Market Pricing
  getMarketPricing: `${environment.apiUrl}/marketpricings`,
  updateMarketPricing: (shopId: string) => `${environment.apiUrl}/marketpricings/${shopId}/update`,
  adminGetMarketPricing: `${environment.apiUrl}/admin/market-pricing`,
  adminUpdateMarketPricing: (shopId: string) => `${environment.apiUrl}/admin/market-pricing/${shopId}`,
  getMarketPricingThresholds: `${environment.apiUrl}/marketpricings/thresholds`,

  // Trends
  getTrends: `${environment.apiUrl}/trends/average`,
  getComptrends: `${environment.apiUrl}/trends/comptrends`,
  updateTrendsInterval: `${environment.apiUrl}/users/custom-trend-interval`,
  getTireTrends: `${environment.apiUrl}/trends/average-tires`,
  updateTireTrendsInterval: `${environment.apiUrl}/users/custom-tire-trend-interval`,

  // Weekly Summary
  weeklySummaryBase: `${environment.apiUrl}/weeklysummary`,
  weeklySummarySales: `${environment.apiUrl}/weeklysummary/sales`,
  weeklySummaryCompSales: `${environment.apiUrl}/weeklysummary/comp-sales`,
  weeklySummaryMonthlyGoals: `${environment.apiUrl}/weeklysummary/monthly-goals`,
  weeklySummaryAverageTrends: `${environment.apiUrl}/weeklysummary/average-trends`,
  weeklySummaryPreviousFourthWeeks: `${environment.apiUrl}/weeklysummary/previous-fourth-weeks`,
  getPublicWeeklyReports: `${environment.apiUrl}/public/weekly-summary-download`,

  // Compare Reports
  getCompareReports: `${environment.apiUrl}/comparator`,
  getCompareAllShopsReports: `${environment.apiUrl}/comparator/all-shops`,

  // Sales Reports
  getSalesReports: `${environment.apiUrl}/sales`,

  // Gross Profit Reports
  getGrossProfitReports: `${environment.apiUrl}/grossprofit`,
  getGrossProfitByTagSize: `${environment.apiUrl}/grossprofit/tag-size`,

  // Rankings Reports
  getRankingsReports: `${environment.apiUrl}/rankings`
}
