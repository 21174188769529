import { ISelectItem } from '@mg-platform/core/core-ui'
import { TableOperator } from './table-filter.interface'

const TableOperatorToLabel: { [key in TableOperator]: string } = {
  LessThan: 'Less than',
  LessThanOrEqual: 'Less than or equal',
  Equals: 'Equals',
  NotEquals: 'Not equal',
  GreaterThanOrEqual: 'Greater than or equal',
  GreaterThan: 'Greater than',
  Contains: 'Contains',
  StartsWith: 'Starts with',
  EndsWith: 'Ends with',
  Period: 'Period'
}

export const TableOperatorToSentence: { [key in TableOperator]: string } = {
  LessThan: 'is less than',
  LessThanOrEqual: 'is less than or equal to',
  Equals: 'is equal to',
  NotEquals: 'is not equal to',
  GreaterThanOrEqual: 'is greater than or equal to',
  GreaterThan: 'is greater than',
  Contains: 'contains',
  StartsWith: 'starts with',
  EndsWith: 'ends with',
  Period: 'in'
}

export const StringOperators: ISelectItem[] = [
  {
    label: TableOperatorToLabel.Contains,
    value: 'Contains'
  },
  {
    label: TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  },
  {
    label: TableOperatorToLabel.StartsWith,
    value: 'StartsWith'
  },
  {
    label: TableOperatorToLabel.EndsWith,
    value: 'EndsWith'
  }
]

export const NumberOperators: ISelectItem[] = [
  {
    label: TableOperatorToLabel.LessThan,
    value: 'LessThan'
  },
  {
    label: TableOperatorToLabel.Equals,
    value: 'Equals'
  },
  {
    label: TableOperatorToLabel.NotEquals,
    value: 'NotEquals'
  },
  {
    label: TableOperatorToLabel.GreaterThan,
    value: 'GreaterThan'
  }
]
