import { ICompTrendsReportRequest, ISelectedShops } from '../../interfaces/comp-trends-report.interface'

export class GetCompTrendsReport {
  static readonly type = '[CompTrendsReport] Get Comp Trends Report'
  constructor(public payload: ICompTrendsReportRequest) {}
}

export class SetSelectedShopsForCompTrends {
  static readonly type = '[CompTrendsReport] Set Selected Shops For Comp Trends'
  constructor(public payload: ISelectedShops) {}
}
