import { IShopGroup, IUpdateShopGroup } from '../../interfaces/shop-groups.interface'

export class UpdateShopGroups {
  static readonly type = '[ShopGroups] Update Shop Groups'
  constructor(public payload: IUpdateShopGroup) {}
}

export class DeleteShopGroup {
  static readonly type = '[ShopGroups] Delete Shop Groups'
  constructor(public groupId: string) {}
}

export class SetShopGroups {
  static readonly type = '[ShopGroups] Set Shop Groups'
  constructor(public shopGroups?: IShopGroup[]) {}
}

export class AutoCreateShopGroups {
  static readonly type = '[ShopGroups] Auto Create Shop Groups'
}
