import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import {
  AdminGetMarketPricing,
  AdminUpdateMarketPricing,
  GetMarketPricing,
  GetMarketPricingThresholds,
  MarketPricingStatusChanged,
  ResetMarketPricing,
  UpdateMarketPricing
} from './market-pricings-reports.actions'
import { MarketPricingReportsStateModel } from './market-pricings-reports.model'
import { isEmpty } from 'lodash'

@State<MarketPricingReportsStateModel>({
  name: 'marketPricingReports'
})
@Injectable()
export class MarketPricingReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static marketPricing(state: MarketPricingReportsStateModel) {
    return state.marketPricing && !isEmpty(state.marketPricing) ? state.marketPricing : undefined
  }

  @Selector()
  static marketPricingChanged(state: MarketPricingReportsStateModel) {
    return state.marketPricingChanged
  }

  @Selector()
  static thresholds(state: MarketPricingReportsStateModel) {
    return state.thresholds
  }

  @Selector()
  static allMarketPricings(state: MarketPricingReportsStateModel) {
    return state.allMarketPricings
  }

  @Selector()
  static paginationInfo(state: MarketPricingReportsStateModel) {
    if (!state.paginationInfo) {
      return
    }
    return {
      ...state.paginationInfo,
      totalPages: state.paginationInfo.totalResults / state.paginationInfo.pageSize + 1
    }
  }

  @Action(GetMarketPricing)
  getMarketPricing(ctx: StateContext<MarketPricingReportsStateModel>, { payload }: GetMarketPricing) {
    return this.reportsService.getMarketPricing(payload).pipe(
      tap((res) => {
        ctx.patchState({ marketPricing: res })
      })
    )
  }

  @Action(UpdateMarketPricing)
  updateMarketPricing(
    ctx: StateContext<MarketPricingReportsStateModel>,
    { payload, shopId }: UpdateMarketPricing
  ) {
    return this.reportsService.updateMarketPricing(payload, shopId)
  }

  @Action(AdminGetMarketPricing)
  adminGetMarketPricing(
    ctx: StateContext<MarketPricingReportsStateModel>,
    { paginatedRequest }: AdminGetMarketPricing
  ) {
    return this.reportsService.adminGetMarketPricing(paginatedRequest).pipe(
      tap((res) => {
        ctx.patchState({
          allMarketPricings: res.items,
          paginationInfo: res.pagination
        })
      })
    )
  }

  @Action(AdminUpdateMarketPricing)
  adminUpdateMarketPricing(
    ctx: StateContext<MarketPricingReportsStateModel>,
    { payload, shopId }: AdminUpdateMarketPricing
  ) {
    return this.reportsService.adminUpdateMarketPricing(payload, shopId)
  }

  @Action(GetMarketPricingThresholds)
  getMarketPricingThresholds(ctx: StateContext<MarketPricingReportsStateModel>) {
    return this.reportsService.getMarketPricingThresholds().pipe(
      tap((res) => {
        ctx.patchState({ thresholds: res })
      })
    )
  }

  @Action(MarketPricingStatusChanged)
  marketPricingStatusChanged(
    ctx: StateContext<MarketPricingReportsStateModel>,
    { payload }: MarketPricingStatusChanged
  ) {
    ctx.patchState({ marketPricingChanged: payload })
  }

  @Action(ResetMarketPricing)
  resetMarketPricing(ctx: StateContext<MarketPricingReportsStateModel>) {
    ctx.patchState({ marketPricing: undefined })
  }
}
