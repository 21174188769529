import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { TargetsService } from '../../targets.service'
import {
  GetTargets,
  GetTargetsForecast,
  GetTargetsHistory,
  RemoveTargets,
  ResetTargets,
  SetTargetsInfo,
  UpdateTargets,
  UpdateTargetsForecast
} from './targets.actions'
import { TargetsStateModel } from './targets.model'
import { isEmpty } from 'lodash'

@State<TargetsStateModel>({
  name: 'targets'
})
@Injectable()
export class TargetsState {
  constructor(private targetsService: TargetsService) {}

  @Selector()
  static targets(state: TargetsStateModel) {
    return state.targets
  }

  @Selector()
  static numberOfZeroValues(state: TargetsStateModel) {
    return state.targetsInfo.filter(
      (el) => el.currentYearValues.sales === 0 || el.currentYearValues.cars === 0
    ).length
  }

  @Selector()
  static isAnyValueChanged(state: TargetsStateModel) {
    if (!state.targetsInfo || !state.targets) {
      return false
    }
    return state.targetsInfo?.some((el, index) => {
      const currentValue = state.targets.currentYearValues[index]
      return (
        el.currentYearValues.sales != currentValue.sales ||
        el.currentYearValues.cars != currentValue.cars ||
        el.currentYearValues.workingDays != currentValue.workingDays
      )
    })
  }

  @Selector()
  static targetsHistory(state: TargetsStateModel) {
    return state.targetsHistory
  }

  @Selector()
  static targetsForecast(state: TargetsStateModel) {
    if (!state.targetsForecast || isEmpty(state.targetsForecast)) {
      return
    }
    return state.targetsForecast
  }

  @Action(GetTargets)
  getTargets(ctx: StateContext<TargetsStateModel>, { shopId }: GetTargets) {
    return this.targetsService.getTargets(shopId).pipe(
      tap((res) => {
        ctx.patchState({
          targets: res
        })
      })
    )
  }

  @Action(UpdateTargets)
  updateTargets(ctx: StateContext<TargetsStateModel>, { shopId }: UpdateTargets) {
    const { targetsInfo } = ctx.getState()
    return this.targetsService
      .updateTargets(
        {
          currentValues: targetsInfo.map((el) => ({
            month: el.currentYearValues.month,
            cars: el.currentYearValues.cars,
            sales: el.currentYearValues.sales,
            workingDays: el.currentYearValues.workingDays
          }))
        },
        shopId
      )
      .pipe(
        tap((res) => {
          ctx.patchState({
            targets: res
          })
        })
      )
  }

  @Action(SetTargetsInfo)
  setTargetsInfo(ctx: StateContext<TargetsStateModel>, { payload }: SetTargetsInfo) {
    ctx.patchState({
      targetsInfo: payload
    })
  }

  @Action(ResetTargets)
  resetTargets(ctx: StateContext<TargetsStateModel>) {
    const { targets } = ctx.getState()
    if (!targets) {
      return
    }
    ctx.patchState({
      targets: {
        ...targets
      }
    })
  }

  @Action(RemoveTargets)
  removeTargets(ctx: StateContext<TargetsStateModel>) {
    ctx.patchState({
      targets: undefined
    })
  }

  @Action(GetTargetsHistory)
  getTargetsHistory(ctx: StateContext<TargetsStateModel>, { payload }: GetTargetsHistory) {
    return this.targetsService.getTargetsHistory(payload).pipe(
      tap((res) => {
        ctx.patchState({
          targetsHistory: res
        })
      })
    )
  }

  @Action(GetTargetsForecast)
  getTargetsForecast(ctx: StateContext<TargetsStateModel>, { payload }: GetTargetsForecast) {
    return this.targetsService.getTargetsForecast(payload).pipe(
      tap((res) => {
        ctx.patchState({
          targetsForecast: res
        })
      })
    )
  }

  @Action(UpdateTargetsForecast)
  updateTargetsForecast(ctx: StateContext<TargetsStateModel>, { payload }: UpdateTargetsForecast) {
    return this.targetsService.updateTargetsForecast(payload).pipe(
      tap((res) => {
        ctx.patchState({
          targetsForecast: res
        })
      })
    )
  }
}
