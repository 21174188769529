import { ICompareWithSelection } from '@mg-platform/core/core-data-access'
import { ITargetsInfo } from '../../interfaces/targets.interface'
import { IUpdateForecastRequest } from '../../interfaces/targets-forecast.interface'

export class GetTargets {
  static readonly type = '[Targets] Get Targets'
  constructor(public shopId: string) {}
}

export class UpdateTargets {
  static readonly type = '[Targets] Update Targets'
  constructor(public shopId: string) {}
}

export class SetTargetsInfo {
  static readonly type = '[Targets] Set Targets Info'
  constructor(public payload: ITargetsInfo[]) {}
}

export class ResetTargets {
  static readonly type = '[Targets] Reset Targets'
}

export class RemoveTargets {
  static readonly type = '[Targets] Remove Targets'
}

export class GetTargetsHistory {
  static readonly type = '[Targets] Get Targets History'
  constructor(public payload: Partial<ICompareWithSelection>) {}
}

export class GetTargetsForecast {
  static readonly type = '[Targets] Get Targets Forecast'
  constructor(public payload: Partial<ICompareWithSelection>) {}
}

export class UpdateTargetsForecast {
  static readonly type = '[Targets] Update Targets Forecast'
  constructor(public payload: IUpdateForecastRequest) {}
}
