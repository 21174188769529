import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import { SalesReportsStateModel } from './sales-reports.model'
import { GetSalesReports } from './sales-reports.actions'

@State<SalesReportsStateModel>({
  name: 'salesReports'
})
@Injectable()
export class SalesReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static reports(state: SalesReportsStateModel) {
    return state.reports
  }

  @Action(GetSalesReports)
  getSalesReports(ctx: StateContext<SalesReportsStateModel>, { payload }: GetSalesReports) {
    return this.reportsService.getSalesReports(payload).pipe(
      tap((res) => {
        ctx.patchState({ reports: res })
      })
    )
  }
}
