@if (boxLike) {
  <div class="w-xl h-xl rounded-sm flex items-center justify-center" [style.backgroundColor]="boxColor">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
}

<ng-template #iconTemplate>
  <div class="flex {{ extraClass }}">
    <svg-icon [key]="icon" class="!text-sm"></svg-icon>
  </div>
</ng-template>
