import { ApplicationRoutes } from '../../app-routes-names'

export const EnterpriseRoutes = {
  dashboard: 'dashboard',
  marketPricing: 'market-pricing'
}

const { enterprisePanel } = ApplicationRoutes

export const EnterprisePaths = {
  dashboard: `/${enterprisePanel}/${EnterpriseRoutes.dashboard}`,
  marketPricing: `/${enterprisePanel}/${EnterpriseRoutes.marketPricing}`
}
