import { FiltersStateModel, FilterStateKeys } from './filters.model'

export class SetFilters {
  static readonly type = '[Filters] Set Filters'
  constructor(public filters: Partial<FiltersStateModel>) {}
}

export class ResetFilters {
  static readonly type = '[Filters] Reset Filters'
  constructor(public filterKeys: FilterStateKeys[]) {}
}

export class ResetCompareWith {
  static readonly type = '[Filters] Reset Compare With'
}

export class SetFiltersFromUrlQueryParams {
  static readonly type = '[Filters] Set Filters From Url QueryParams'
  constructor(public filtersToken: string) {}
}

export class SetUrlQueryParamsFromState {
  static readonly type = '[Filters] Set Url Query Params From State'
}
