import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { UserAccountPaths } from '../../features/user-account/user-account-route-names'
import { UsersService } from '@mg-platform/users/users-data-access'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AutovitalsGuard {
  constructor(private router: Router, private userService: UsersService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const token = route.paramMap.get('token')
    if (token) {
      try {
        await firstValueFrom(
          this.userService.loginAutovitals({
            token
          })
        )
        this.router.navigateByUrl(UserAccountPaths.LoginPath)
      } catch (error) {
        this.router.navigateByUrl(UserAccountPaths.LoginPath)
      }
    }

    return true
  }
}
