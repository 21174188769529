<div class="flex items-start">
  <div class="flex-1">
    <div class="text-base leading-base font-medium">Included Shops</div>
    <div class="mg-card-subtitle">Shops that have enough data.</div>
  </div>

  <div class="md:hidden flex ml-2xl cursor-pointer text-txt-secondary">
    <svg-icon key="close" class="!text-base" (click)="canceld.emit()"></svg-icon>
  </div>
</div>

<div class="mt-2xl md:mt-xl flex flex-wrap gap-sm">
  <mg-pill
    *ngFor="let item of shopsList"
    [label]="item.displayName"
    [size]="pillSize"
    [hasHoverEffect]="false"
    extraClass="!cursor-auto"
  >
  </mg-pill>
</div>
