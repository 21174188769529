export enum MarketType {
  local = 'local',
  organization = 'organization',
  state = 'state',
  regional = 'regional',
  national = 'national',
  custom = 'custom',
  shop = 'shop',
  group = 'group'
}
