<div
  class="w-full h-full bg-transparent"
  [ngClass]="{ 'cursor-pointer': clickable }"
  [ngxTippy]="tooltipTemplate"
  tippyClassName="mg-tippy-tooltip"
  [tippyProps]="{
    arrow: false,
    followCursor: true,
    hideOnClick: clickable,
    placement: 'bottom-start'
  }"
  (click)="onClick($event)"
></div>

<ng-template #tooltipTemplate>
  <ng-content></ng-content>
</ng-template>
