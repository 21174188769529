<mg-add-shop
  *ngIf="activeSection === 'add'"
  [addNewShop]="addNewShop"
  [cancelBtnLabel]="cancelBtnLabel"
  (canceld)="canceld.emit()"
  (completed)="activeSection = 'focus'"
></mg-add-shop>
<mg-add-shop-focus
  *ngIf="activeSection === 'focus'"
  [addNewShop]="addNewShop"
  (completed)="activeSection = 'service-price'"
></mg-add-shop-focus>
<mg-add-shop-service-price
  *ngIf="activeSection === 'service-price'"
  [addNewShop]="addNewShop"
  (completed)="completed.emit()"
></mg-add-shop-service-price>
