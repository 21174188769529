import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { IShop } from '@mg-platform/shops/shops-data-access'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-included-shops-dialog',
  templateUrl: './included-shops-dialog.component.html',
  styleUrls: ['./included-shops-dialog.component.scss']
})
export class IncludedShopsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<IncludedShopsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shopsList: IShop[]
    }
  ) {}
}
