import { Injectable } from '@angular/core'
import { HttpService } from '@mg-platform/core/core-data-access'
import { CONTACT_API_URL } from './contact-api-routes'
import { IContactRequest } from './interfaces/contact-request.interface'

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpService) {}

  sendContactRequest(payload: IContactRequest) {
    return this.http.post(CONTACT_API_URL.contact, payload)
  }
}
