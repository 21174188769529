export class GetShopUsers {
  static readonly type = '[ShopUsers] Get Shop Users'
  constructor(public shopId: string) {}
}

export class GetShopPendingUsers {
  static readonly type = '[ShopUsers] Get Shop Pending Users'
  constructor(public shopId: string) {}
}

export class DeleteShopUser {
  static readonly type = '[ShopUsers] Delete Shop User'
  constructor(public shopId: string, public userId: string) {}
}
