import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IHeaderRouteInfo } from '../main-header/main-header.interface'
import { Store } from '@ngxs/store'
import { Logout } from '@mg-platform/users/users-data-access'
import { ResetShopState } from '@mg-platform/shops/shops-data-access'
import { ResetFilters } from '@mg-platform/core/core-data-access'
import { Router } from '@angular/router'

@UntilDestroy()
@Component({
  selector: 'mg-menu-header-items-dialog',
  templateUrl: './menu-header-items-dialog.component.html',
  styleUrls: ['./menu-header-items-dialog.component.scss']
})
export class MenuHeaderItemsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MenuHeaderItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { routes: IHeaderRouteInfo[]; showLogout: boolean },
    private store: Store,
    private router: Router
  ) {}

  closeDialog() {
    setTimeout(() => {
      this.dialogRef.close()
    }, 200)
  }

  logout() {
    this.store.dispatch(new Logout()).subscribe(() => {
      this.store.dispatch([
        new ResetShopState(),
        new ResetFilters(['shopId', 'marketType', 'weekNumber', 'compareWith'])
      ])
      this.router.navigateByUrl('/login')
      this.dialogRef.close()
    })
  }
}
