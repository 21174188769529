import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { LOCALSTORAGE_KEYS } from '@mg-platform/core/core-util'
import { UserAccountPaths } from '../../features/user-account/user-account-route-names'
import { SetInvitedUserInfo, UsersService } from '@mg-platform/users/users-data-access'
import { firstValueFrom } from 'rxjs'
import { Store } from '@ngxs/store'

@Injectable({
  providedIn: 'root'
})
export class InvitationGuard {
  constructor(private router: Router, private userService: UsersService, private store: Store) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const token = route.paramMap.get('token')
    if (token) {
      localStorage.setItem(LOCALSTORAGE_KEYS.INVITATION_TOKEN, token)
      try {
        const res = await firstValueFrom(this.userService.checkInvitedUser(token))
        this.store.dispatch(new SetInvitedUserInfo(res))
        if (res?.isUserExist || res?.errorCodes?.InvitationArchived === '8001') {
          this.router.navigateByUrl(UserAccountPaths.LoginPath)
        } else {
          this.router.navigateByUrl(UserAccountPaths.SignupPath)
        }
      } catch (error) {
        this.router.navigateByUrl(UserAccountPaths.LoginPath)
      }
    }

    return true
  }
}
