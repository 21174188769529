import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root'
})
export class ShowErrorService {
  constructor(private toastrService: ToastrService) {}

  showHttpError(err: any) {
    const error: string = err?.error?.errorMessage
    if (error) {
      this.toastrService.error(error)
    } else {
      this.toastrService.error('You are offline or a server error occurred. Please try again later.')
    }
  }
}
