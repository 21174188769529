import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { environment } from '@mg-platform/core/core-util'
import { AppModule } from './app/app.module'

if (environment.production) {
  enableProdMode()
}

const setGooglePlacesScript = () => {
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googlePlacesKey}&libraries=places&language=en`
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(script)
}

setGooglePlacesScript()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
