export enum RankingsColumns {
  sales = 'sales',
  cars = 'cars',
  averageTicket = 'averageTicket',
  grossProfit = 'grossProfit',
  tireSales = 'tireSales'
}

export const RankingsColumnsToLabel: { [key: string]: string } = {
  sales: 'Sales',
  cars: 'Cars',
  averageTicket: 'Average ticket',
  grossProfit: 'Gross profit',
  tireSales: 'Tire sales'
}

export interface IRankingItem {
  index: number
  id: string
  displayText: string
  value: number
  formattedValue: string
}

export interface IRankingItemExtended {
  data: IRankingItem[]
  title: string
  key: RankingsColumns
}

export interface IRankingResponse {
  sales?: IRankingItem[]
  cars?: IRankingItem[]
  averageTicket?: IRankingItem[]
  grossProfit?: IRankingItem[]
  tireSales?: IRankingItem[]
}
