<div class="p-lg md:p-0 flex-1 flex flex-col md:justify-center bg-contrast">
  <mg-add-shop-sections [addNewShop]="addNewShop" [activeSection]="3"></mg-add-shop-sections>

  <div class="title pt-base">Select shop focus</div>
  <div class="sub-title pb-2xl">Select the product mix that best describes your shop.</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex-1 md:flex-none flex flex-col">
    <div class="flex-1 md:flex-none flex flex-col">
      <div class="mt-lg first:mt-0" *ngFor="let item of shopFocusItems">
        <mg-radio [item]="item" controlName="focusType"></mg-radio>
      </div>
    </div>

    <div *ngIf="addNewShop" class="mt-lg w-full hidden md:flex flex-row items-center justify-end">
      <mg-button
        class="mr-2xl"
        type="button"
        variant="ghost"
        size="small-shrink"
        (click)="completed.emit()"
      >
        Skip
      </mg-button>
      <mg-button
        type="submit"
        variant="ghost"
        size="small-shrink"
        icon="arrow-right-full"
        iconPosition="right"
        iconType="svgIcon"
        iconClass="!text-sm"
        [loading]="isProcessing"
        [disabled]="form.invalid"
        extraBtnClass="{{ isProcessing ? 'bg-contrast' : '' }}"
      >
        Continue
      </mg-button>
    </div>

    <div class="w-full mt-2xl flex flex-col" [ngClass]="{ 'md:hidden': addNewShop }">
      <mg-button
        class="w-full"
        type="submit"
        size="small"
        icon="arrow-right-full"
        iconPosition="right"
        iconType="svgIcon"
        iconClass="!text-sm"
        [loading]="isProcessing"
        [disabled]="form.invalid"
      >
        Continue
      </mg-button>
      <mg-button
        class="mt-base md:pb-base w-full"
        type="button"
        size="small"
        variant="ghost"
        (click)="completed.emit()"
      >
        Skip
      </mg-button>
    </div>
  </form>
</div>
