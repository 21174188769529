import { IUpdateUserNotificationsRequest } from '../../interfaces/user-notifications.interface'

export class GetUserNotificationsConfig {
  static readonly type = '[userNotifications] Get User Notifications Config'
}

export class UpdateUserNotificationsConfig {
  static readonly type = '[userNotifications] Update User Notifications Config'
  constructor(public payload: IUpdateUserNotificationsRequest) {}
}
