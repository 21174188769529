export const TrendsRoutes = {
  allTrends: 'weekly-trends',
  compTrends: 'comp-trends',
  tireTrends: 'tire-trends'
}

export const TrendsPaths = {
  allTrends: `/${TrendsRoutes.allTrends}`,
  compTrends: `/${TrendsRoutes.compTrends}`,
  tireTrends: `/${TrendsRoutes.tireTrends}`
}
