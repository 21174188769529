import { ICreateShopRequest } from '../../interfaces/create-shop.interface'
import { IShopFocusRequest } from '../../interfaces/shop-focus.interface'
import { IUpdateShopRequest } from '../../interfaces/update-shop.interface'

export class CreateShop {
  static readonly type = '[Shops] Create Shop'
  constructor(public payload: ICreateShopRequest) {}
}

export class UpdateShop {
  static readonly type = '[Shops] Update Shop'
  constructor(
    public payload: IUpdateShopRequest,
    public shopId: string
  ) {}
}

export class SetShopFocus {
  static readonly type = '[Shops] Set Shop Focus'
  constructor(
    public payload: IShopFocusRequest,
    public shopId: string
  ) {}
}

export class GetAllUserShops {
  static readonly type = '[Shops] Get All User Shops'
}

export class GetOneUserShops {
  static readonly type = '[Shops] Get One User Shops'
  constructor(
    public payload: {
      shopId: string
      refresh?: boolean
      withLastReportDate?: boolean
    }
  ) {}
}

export class ResetShopState {
  static readonly type = '[Shops] Rest Shop State'
}

export class ResetShop {
  static readonly type = '[Shops] Rest Shop'
}

export class GetOneShopFromAllShops {
  static readonly type = '[Shops] Get One Shop From All Shops'
  constructor(public shopId: string) {}
}

export class SetOrganizationAverageAsShop {
  static readonly type = '[Shops] Set Organization Average As Shop'
}
