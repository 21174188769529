<div class="p-lg md:p-0 flex-1 flex flex-col md:justify-center bg-contrast">
  <mg-add-shop-sections
    [addNewShop]="addNewShop"
    [activeSection]="activeSection === 'contact' ? 2 : 1"
  ></mg-add-shop-sections>

  <ng-container [ngSwitch]="activeSection">
    <ng-container *ngSwitchCase="'place'">
      <div class="title pt-base">Locate your shop</div>
      <div class="sub-title pb-lg">Find your shop on the map or enter the address manually.</div>

      <form [formGroup]="googlePlaceForm" class="flex-1 md:flex-none flex flex-col items-center">
        <mg-google-place-input
          class="w-full flex-1"
          (addressChanged)="shopAddressSelected($event)"
          placeholder="Enter your shop location"
          [googlePlacesCountries]="googlePlacesCountries"
          controlName="location"
        ></mg-google-place-input>

        <div *ngIf="addNewShop" class="mt-lg w-full hidden md:flex flex-row items-center justify-end">
          <mg-button
            class="mr-2xl"
            type="button"
            variant="ghost"
            size="small-shrink"
            (click)="canceld.emit()"
          >
            {{ cancelBtnLabel }}
          </mg-button>
          <mg-button type="button" variant="ghost" size="small-shrink" (click)="addManually()">
            Add manually
          </mg-button>
        </div>

        <div
          class="w-full flex flex-col"
          [ngClass]="{ 'md:hidden mt-lg': addNewShop, 'mt-2xl': !addNewShop }"
        >
          <mg-button class="w-full" type="button" size="small" (click)="addManually()">
            Add manually
          </mg-button>
          <mg-button
            class="mt-base w-full"
            type="button"
            size="small"
            variant="ghost"
            (click)="canceld.emit()"
          >
            {{ cancelBtnLabel }}
          </mg-button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="title pt-base">
        <ng-container *ngIf="activeSection === 'details'"> Enter shop details </ng-container>
        <ng-container *ngIf="activeSection === 'contact'"> Add contact info </ng-container>
      </div>
      <div class="sub-title pb-lg">
        <ng-container *ngIf="activeSection === 'details'">
          Fill in your shop's name and address information.
        </ng-container>
        <ng-container *ngIf="activeSection === 'contact'">
          Fill in your shop's contact information.
        </ng-container>
      </div>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex-1 md:flex-none flex flex-col">
        <ng-container *ngIf="activeSection === 'details'">
          <div class="flex-1 md:flex-none flex flex-col">
            <mg-input controlName="name" type="text" label="Shop Name"></mg-input>
            <mg-input
              controlName="countryCode"
              type="select"
              label="Country"
              [items]="allCountries"
            ></mg-input>
            <mg-input controlName="address" type="text" label="Address"></mg-input>
            <mg-input controlName="city" type="text" label="City"></mg-input>
            <mg-input
              controlName="postCode"
              type="text"
              label="Zip Code"
              [errorMessages]="[
                {
                  type: 'required',
                  message: 'This is required'
                }
              ]"
            ></mg-input>
            <mg-input
              controlName="stateId"
              type="select"
              label="State"
              [items]="states"
              [loading]="loadingStates"
            ></mg-input>
          </div>

          <div *ngIf="addNewShop" class="mt-lg w-full hidden md:flex flex-row items-center justify-end">
            <mg-button
              class="mr-2xl"
              type="button"
              variant="ghost"
              size="small-shrink"
              (click)="googlePlaceForm.reset(); form.reset(); activeSection = 'place'"
            >
              Back
            </mg-button>
            <mg-button
              type="button"
              variant="ghost"
              size="small-shrink"
              icon="arrow-right-full"
              iconPosition="right"
              iconType="svgIcon"
              iconClass="!text-sm"
              [disabled]="detailsFormIsInvalid()"
              (click)="continueToContactForm()"
            >
              Continue
            </mg-button>
          </div>

          <div class="w-full mt-lg flex flex-col" [ngClass]="{ 'md:hidden': addNewShop }">
            <mg-button
              class="w-full"
              type="button"
              size="small"
              icon="arrow-right-full"
              iconPosition="right"
              iconType="svgIcon"
              [disabled]="detailsFormIsInvalid()"
              (click)="continueToContactForm()"
            >
              Continue
            </mg-button>
            <mg-button
              class="mt-base md:pb-base w-full"
              type="button"
              size="small"
              variant="ghost"
              (click)="googlePlaceForm.reset(); form.reset(); activeSection = 'place'"
            >
              Back
            </mg-button>
          </div>
        </ng-container>

        <ng-container *ngIf="activeSection === 'contact'">
          <div class="flex-1 md:flex-none flex flex-col">
            <mg-input
              controlName="phoneNumber"
              type="text"
              label="Phone Number"
              mask="(000) 000-0000"
              prefix="+1"
              [errorMessages]="[
                {
                  type: 'US-phone',
                  message: 'This is not is US/CA valid phone number'
                }
              ]"
            ></mg-input>
            <mg-input
              controlName="websiteUrl"
              type="text"
              label="Website"
              [errorMessages]="[
                {
                  type: 'invalidUrl',
                  message: 'Please enter a valid url'
                }
              ]"
            ></mg-input>
            <mg-input controlName="nickname" type="text" label="Shop Nickname"></mg-input>
            <mg-input
              controlName="numberOfBays"
              type="number"
              label="Bays at Shop"
              [errorMessages]="[
                {
                  type: 'min',
                  message: 'Can not be less than 1'
                },
                {
                  type: 'max',
                  message: 'Can not be more than 50'
                }
              ]"
            ></mg-input>
          </div>

          <div *ngIf="addNewShop" class="mt-lg w-full hidden md:flex flex-row items-center justify-end">
            <mg-button
              class="mr-2xl"
              type="button"
              variant="ghost"
              size="small-shrink"
              (click)="activeSection = 'details'"
            >
              Back
            </mg-button>
            <mg-button
              type="submit"
              variant="ghost"
              size="small-shrink"
              icon="arrow-right-full"
              iconPosition="right"
              iconType="svgIcon"
              [loading]="isProcessing"
              [disabled]="form.invalid"
              extraBtnClass="{{ isProcessing ? 'bg-contrast' : '' }}"
            >
              Continue
            </mg-button>
          </div>

          <div class="w-full mt-lg flex flex-col" [ngClass]="{ 'md:hidden': addNewShop }">
            <mg-button
              class="w-full"
              type="submit"
              size="small"
              icon="arrow-right-full"
              iconPosition="right"
              iconType="svgIcon"
              iconClass="!text-sm"
              [loading]="isProcessing"
              [disabled]="form.invalid"
            >
              Continue
            </mg-button>
            <mg-button
              class="mt-base md:pb-base w-full"
              type="button"
              size="small"
              variant="ghost"
              (click)="activeSection = 'details'"
            >
              Back
            </mg-button>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </ng-container>
</div>
