import { Injectable } from '@angular/core'
import { convertPaginationRequest, HttpService } from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IAllNotificationsResponse, INotificationRequest } from './interfaces/notifications.interface'
import { NOTIFICATIONS_API_URL } from './notifications-api-routes'
import {
  IUpdateUserNotificationsRequest,
  IUserNotificationsResponse
} from './interfaces/user-notifications.interface'

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private http: HttpService) {}

  getAllNotifications(request: INotificationRequest): Observable<IAllNotificationsResponse> {
    const params = { ...convertPaginationRequest(request) }
    if (request.channel) {
      params['channel'] = request.channel.toString()
    }
    if (request.type) {
      params['type'] = request.type.toString()
    }
    return this.http.get<IAllNotificationsResponse>(NOTIFICATIONS_API_URL.all, { params })
  }

  getUserNotificationsConfig(): Observable<IUserNotificationsResponse> {
    return this.http.get<IUserNotificationsResponse>(NOTIFICATIONS_API_URL.getUserNotificationsConfig)
  }

  updateUserNotificationsConfig(payload: IUpdateUserNotificationsRequest) {
    return this.http.post(NOTIFICATIONS_API_URL.updateUserNotificationsConfig, payload)
  }
}
