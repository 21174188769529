import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import {
  GetAvailableWeeks,
  GetPublicWeeklyReports,
  GetWeeklyReportsAverageTrends,
  GetWeeklyReportsCompSales,
  GetWeeklyReportsMonthlyGoals,
  GetWeeklyReportsPreviousFourthWeeks,
  GetWeeklyReportsSales
} from './weekly-reports.actions'
import { WeeklyReportsStateModel } from './weekly-reports.model'
import { isEmpty } from 'lodash'
import { IPublicWeeklyReportSummary } from '../../interfaces/public-weekly-report.interface'
import {
  IWeeklyReportMonthlyGoals,
  IWeeklyReportPreviousFourthWeeks,
  IWeeklyReportSales
} from '../../interfaces/weekly-report.interface'
import { ICompareResponse } from '../../interfaces/compare-report.interface'
import { IAverageTrendResponse } from '../../interfaces/average-trends.interface'

@State<WeeklyReportsStateModel>({
  name: 'weeklyReports'
})
@Injectable()
export class WeeklyReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static sales(state: WeeklyReportsStateModel): IWeeklyReportSales | undefined {
    if (isEmpty(state.sales) || !state.sales.weeklySalesSummary) {
      return
    }
    const { weeklySalesSummary } = state.sales

    return {
      weeklySalesSummary: isEmpty(weeklySalesSummary) ? undefined : weeklySalesSummary
    }
  }

  @Selector()
  static compSales(state: WeeklyReportsStateModel): ICompareResponse | undefined {
    return state.compSales
  }

  @Selector()
  static monthlyGoals(state: WeeklyReportsStateModel): IWeeklyReportMonthlyGoals | undefined {
    if (isEmpty(state.monthlyGoals) || !state.monthlyGoals.monthlyGoal) {
      return
    }
    const { monthlyGoal } = state.monthlyGoals

    return {
      monthlyGoal: isEmpty(monthlyGoal) ? undefined : monthlyGoal
    }
  }

  @Selector()
  static averageTrends(state: WeeklyReportsStateModel): IAverageTrendResponse | undefined {
    return state.averageTrends
  }

  @Selector()
  static previousFourthWeeks(
    state: WeeklyReportsStateModel
  ): IWeeklyReportPreviousFourthWeeks | undefined {
    if (isEmpty(state.previousFourthWeeks) || !state.previousFourthWeeks.my4WeekShop) {
      return
    }
    const { my4WeekShop } = state.previousFourthWeeks

    return {
      my4WeekShop: isEmpty(my4WeekShop) ? undefined : my4WeekShop
    }
  }

  @Selector()
  static publicReports(state: WeeklyReportsStateModel): IPublicWeeklyReportSummary | undefined {
    if (isEmpty(state.publicReports)) {
      return
    }
    const { shopName, subtitle, summary } = state.publicReports

    return {
      shopName,
      subtitle,
      my4WeekShop: isEmpty(summary?.my4WeekShop) ? undefined : summary?.my4WeekShop,
      weeklySalesSummary: isEmpty(summary?.weeklySalesSummary) ? undefined : summary?.weeklySalesSummary,
      comparativeSales: isEmpty(summary?.comparativeSales) ? undefined : summary?.comparativeSales,
      monthlyGoal: isEmpty(summary?.monthlyGoal) ? undefined : summary?.monthlyGoal,
      trendSummary: isEmpty(summary?.trendSummary) ? undefined : summary?.trendSummary
    }
  }

  @Selector()
  static availableWeeks(state: WeeklyReportsStateModel) {
    return state.availableWeeks
  }

  @Action(GetWeeklyReportsSales)
  getWeeklyReportsSales(ctx: StateContext<WeeklyReportsStateModel>, { payload }: GetWeeklyReportsSales) {
    return this.reportsService.getWeeklyReportsSales(payload).pipe(
      tap((res) => {
        ctx.patchState({ sales: res })
      })
    )
  }

  @Action(GetWeeklyReportsCompSales)
  getWeeklyReportsCompSales(
    ctx: StateContext<WeeklyReportsStateModel>,
    { payload }: GetWeeklyReportsCompSales
  ) {
    return this.reportsService.getWeeklyReportsCompSales(payload).pipe(
      tap((res) => {
        ctx.patchState({ compSales: res })
      })
    )
  }

  @Action(GetWeeklyReportsMonthlyGoals)
  getWeeklyReportsMonthlyGoals(
    ctx: StateContext<WeeklyReportsStateModel>,
    { payload }: GetWeeklyReportsMonthlyGoals
  ) {
    return this.reportsService.getWeeklyReportsMonthlyGoals(payload).pipe(
      tap((res) => {
        ctx.patchState({ monthlyGoals: res })
      })
    )
  }

  @Action(GetWeeklyReportsAverageTrends)
  getWeeklyReportsAverageTrends(
    ctx: StateContext<WeeklyReportsStateModel>,
    { payload }: GetWeeklyReportsAverageTrends
  ) {
    return this.reportsService.getWeeklyReportsAverageTrends(payload).pipe(
      tap((res) => {
        ctx.patchState({ averageTrends: res })
      })
    )
  }

  @Action(GetWeeklyReportsPreviousFourthWeeks)
  getWeeklyReportsPreviousFourthWeeks(
    ctx: StateContext<WeeklyReportsStateModel>,
    { payload }: GetWeeklyReportsPreviousFourthWeeks
  ) {
    return this.reportsService.getWeeklyReportsPreviousFourthWeeks(payload).pipe(
      tap((res) => {
        ctx.patchState({ previousFourthWeeks: res })
      })
    )
  }

  @Action(GetAvailableWeeks)
  getAvailableWeeks(ctx: StateContext<WeeklyReportsStateModel>) {
    ctx.patchState({ availableWeeks: undefined })
    return this.reportsService.getAvailableWeeks().pipe(
      tap((res) => {
        ctx.patchState({ availableWeeks: res.availableWeeks })
      })
    )
  }

  @Action(GetPublicWeeklyReports)
  getPublicWeeklyReports(ctx: StateContext<WeeklyReportsStateModel>, { token }: GetPublicWeeklyReports) {
    return this.reportsService.getPublicWeeklyReports(token).pipe(
      tap((res) => {
        ctx.patchState({ publicReports: res })
      })
    )
  }
}
