import { Injectable } from '@angular/core'
import { HttpService, ICompareWithSelection } from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { ITargetsResponse, ITargetsUpdateRequest } from './interfaces/targets.interface'
import { TARGETS_API_URL } from './targets-api-routes'
import { ITargetsHistoryResponse } from './interfaces/targets-history.interface'
import {
  ITargetsForecastResponse,
  IUpdateForecastRequest
} from './interfaces/targets-forecast.interface'

@Injectable({
  providedIn: 'root'
})
export class TargetsService {
  constructor(private http: HttpService) {}

  getTargetsHistory(payload: Partial<ICompareWithSelection>): Observable<ITargetsHistoryResponse> {
    return this.http.post<ITargetsHistoryResponse>(TARGETS_API_URL.getHistory, payload)
  }

  getTargetsForecast(payload: Partial<ICompareWithSelection>): Observable<ITargetsForecastResponse> {
    return this.http.post<ITargetsForecastResponse>(TARGETS_API_URL.getForecast, payload)
  }

  updateTargetsForecast(payload: IUpdateForecastRequest): Observable<ITargetsForecastResponse> {
    return this.http.post<ITargetsForecastResponse>(TARGETS_API_URL.updateForecast, payload)
  }

  getTargets(shopId: string): Observable<ITargetsResponse> {
    return this.http.get<ITargetsResponse>(TARGETS_API_URL.targetsBase(shopId))
  }

  updateTargets(payload: ITargetsUpdateRequest, shopId: string): Observable<ITargetsResponse> {
    return this.http.put<ITargetsResponse>(TARGETS_API_URL.targetsBase(shopId), payload)
  }
}
