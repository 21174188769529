import * as Chartjs from 'chart.js'
import { DeepPartial } from 'chart.js/types/utils'

export const ChartTooltipOptions: DeepPartial<Chartjs.TooltipOptions> = {
  displayColors: false,
  caretSize: 0,
  caretPadding: 10,
  backgroundColor: '#FFFFFF',
  bodyColor: '#112027',
  titleColor: '#112027',
  footerColor: '#6B7980',
  borderWidth: 1,
  borderColor: '#dbdbdb',
  cornerRadius: 4,
  padding: {
    left: 8,
    right: 8,
    top: 8,
    bottom: 6
  },
  bodyFont: {
    family: 'Poppins',
    size: 12,
    lineHeight: 1.6
  },
  titleFont: {
    family: 'Poppins',
    size: 12,
    weight: '500'
  },
  footerFont: {
    family: 'Poppins',
    weight: '400',
    size: 10
  }
}
