export enum NotificationType {
  Other = 'Other',
  FirstDataReminderEarly = 'FirstDataReminderEarly',
  SecondDataReminderEarly = 'SecondDataReminderEarly',
  General = 'General',
  MeVsMeAvgTicket = 'MeVsMeAvgTicket',
  MeVsMeAvgCarCount = 'MeVsMeAvgCarCount',
  MeVsMarketAvgTicket = 'MeVsMarketAvgTicket',
  MeVsMarketAvgCarCount = 'MeVsMarketAvgCarCount',
  ShopVerified = 'ShopVerified',
  FirstDataReminderLate = 'FirstDataReminderLate',
  SecondDataReminderLate = 'SecondDataReminderLate',
  ShopRequiresVerification = 'ShopRequiresVerification',
  ResetPassword = 'ResetPassword',
  ContactUs = 'ContactUs',
  Invitation = 'Invitation',
  WeeklySummary = 'WeeklySummary',
  MaxNumbersUpdated = 'MaxNumbersUpdated',
  GreenDaysCarCount = 'GreenDaysCarCount',
  RedDaysCarCount = 'RedDaysCarCount',
  GreenDaysAvgTicket = 'GreenDaysAvgTicket',
  RedDaysAvgTicket = 'RedDaysAvgTicket',
  MinMaxNumbersMarketPricing = 'MinMaxNumbersMarketPricing',
  State4WeeksTrend = 'State4WeeksTrend',
  GoalPacing = 'GoalPacing',
  CompSales = 'CompSales',
  StateMomentum = 'StateMomentum'
}
