export enum UserPermissionType {
  notSet = 'NotSet',
  view = 'View',
  add = 'Add',
  edit = 'Edit',
  delete = 'Delete',
  inviteOthers = 'InviteOthers',
  canCreateShop = 'CanCreateShop',
  goals = 'Goals'
}
