import { Injectable } from '@angular/core'
import { ShopsService } from '../../shops.service'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import {
  CreateShop,
  GetAllUserShops,
  GetOneShopFromAllShops,
  GetOneUserShops,
  ResetShop,
  ResetShopState,
  SetOrganizationAverageAsShop,
  SetShopFocus,
  UpdateShop
} from './shops.actions'
import { ShopsStateModel } from './shops.model'
import { ORGANIZATION_AVERAGE } from '@mg-platform/core/core-util'
import moment from 'moment'
import { cloneDeep, uniq } from 'lodash'
import { SetShopGroups } from '../shop-groups/shop-groups.actions'

@State<ShopsStateModel>({
  name: 'shops'
})
@Injectable()
export class ShopsState {
  constructor(
    private shopsService: ShopsService,
    private store: Store
  ) {}

  @Selector()
  static shop(state: ShopsStateModel) {
    return state.shop
  }

  @Selector()
  static organizationShops(state: ShopsStateModel) {
    return state.organizationShops
  }

  @Selector()
  static allShops(state: ShopsStateModel) {
    return state.allShops
  }

  @Selector()
  static hasShopInMoreThanOneState(state: ShopsStateModel) {
    return uniq(state.allShops?.map((el) => el.stateId) ?? []).length > 1
  }

  @Action(CreateShop)
  createShop(ctx: StateContext<ShopsStateModel>, { payload }: CreateShop) {
    return this.shopsService.createShop(payload).pipe(
      tap((res) => {
        ctx.patchState({ shop: res })
      })
    )
  }

  @Action(UpdateShop, { cancelUncompleted: true })
  updateShop(ctx: StateContext<ShopsStateModel>, { payload, shopId }: UpdateShop) {
    return this.shopsService.updateShop(payload, shopId).pipe(
      tap((res) => {
        ctx.patchState({ shop: res })
      })
    )
  }

  @Action(SetShopFocus, { cancelUncompleted: true })
  setShopFocus(ctx: StateContext<ShopsStateModel>, { payload, shopId }: SetShopFocus) {
    const allShops = ctx.getState()?.allShops
    return this.shopsService.setShopFocus(payload, shopId).pipe(
      tap(() => {
        const shopIndex = allShops?.findIndex((el) => el.id === shopId)
        if (shopIndex > -1) {
          const shopsTemp = cloneDeep(allShops)
          shopsTemp[shopIndex].focus = payload.focusType

          ctx.patchState({ allShops: shopsTemp })
        }
      })
    )
  }

  @Action(GetAllUserShops)
  getAllUserShops(ctx: StateContext<ShopsStateModel>) {
    return this.shopsService.getAllUserShops().pipe(
      tap((res) => {
        const allShops = res?.myShops ?? []
        const organizationShops = res?.organizationShops ?? []
        ctx.patchState({ allShops, organizationShops })
        this.store.dispatch(new SetShopGroups(res?.groups))
      })
    )
  }

  @Action(GetOneUserShops, { cancelUncompleted: true })
  getOneUserShops(ctx: StateContext<ShopsStateModel>, { payload }: GetOneUserShops) {
    if (payload.refresh) {
      ctx.patchState({ shop: undefined })
    }
    let shopId = payload.shopId
    const { allShops } = ctx.getState()
    if (allShops?.length > 0 && !allShops.find((el) => el.id === shopId)) {
      shopId = allShops[0].id
    }
    return this.shopsService.getOneUserShops(shopId, payload.withLastReportDate).pipe(
      tap((res) => {
        ctx.patchState({ shop: res })
      })
    )
  }

  @Action(GetOneShopFromAllShops)
  GetOneShopFromAllShops(ctx: StateContext<ShopsStateModel>, { shopId }: GetOneShopFromAllShops) {
    const { allShops } = ctx.getState()
    let shop = allShops.find((el) => el.id === shopId)
    if (allShops?.length > 0 && !shop) {
      shop = allShops[0]
      shopId = shop.id
    }
    ctx.patchState({ shop })
  }

  @Action(SetOrganizationAverageAsShop)
  setOrganizationAverageAsShop(ctx: StateContext<ShopsStateModel>) {
    const { shop } = ctx.getState()

    if (shop?.id && shop.id === ORGANIZATION_AVERAGE.id) {
      return
    }

    ctx.patchState({
      shop: {
        id: ORGANIZATION_AVERAGE.id,
        isVerified: true,
        name: ORGANIZATION_AVERAGE.name,
        nickname: ORGANIZATION_AVERAGE.name,
        displayName: ORGANIZATION_AVERAGE.name,
        created: moment().startOf('year').toDate(),
        phoneNumber: '',
        websiteUrl: '',
        numberOfBays: 20
      }
    })
  }

  @Action(ResetShopState)
  resetShopState(ctx: StateContext<ShopsStateModel>) {
    ctx.patchState({ shop: undefined, allShops: undefined })
  }

  @Action(ResetShop)
  resetShop(ctx: StateContext<ShopsStateModel>) {
    ctx.patchState({ shop: undefined })
  }
}
