import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import { RankingsReportsStateModel } from './rankings-reports.model'
import { GetRankingsReports } from './rankings-reports.actions'
import { isEmpty } from 'lodash'
import {
  IRankingItemExtended,
  RankingsColumns,
  RankingsColumnsToLabel
} from '../../interfaces/rankings-report.interface'

@State<RankingsReportsStateModel>({
  name: 'rankingsReports'
})
@Injectable()
export class RankingsReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static reports(state: RankingsReportsStateModel): IRankingItemExtended[] | undefined {
    if (isEmpty(state.reports)) {
      return
    }

    return [
      state.reports.sales
        ? {
            data: state.reports.sales,
            key: RankingsColumns.sales,
            title: RankingsColumnsToLabel[RankingsColumns.sales]
          }
        : undefined,
      state.reports.cars
        ? {
            data: state.reports.cars,
            key: RankingsColumns.cars,
            title: RankingsColumnsToLabel[RankingsColumns.cars]
          }
        : undefined,
      state.reports.averageTicket
        ? {
            data: state.reports.averageTicket,
            key: RankingsColumns.averageTicket,
            title: RankingsColumnsToLabel[RankingsColumns.averageTicket]
          }
        : undefined,
      state.reports.grossProfit
        ? {
            data: state.reports.grossProfit,
            key: RankingsColumns.grossProfit,
            title: RankingsColumnsToLabel[RankingsColumns.grossProfit]
          }
        : undefined
    ].filter((el) => !!el)
  }

  @Action(GetRankingsReports)
  getRankingsReports(ctx: StateContext<RankingsReportsStateModel>, { payload }: GetRankingsReports) {
    return this.reportsService.getRankingsReports(payload).pipe(
      tap((res) => {
        ctx.patchState({ reports: res })
      })
    )
  }
}
