import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { LOCALSTORAGE_KEYS } from '../constants/localstorage-keys.constant'
import { environment } from '../environments/environment'

export type DarkModeType = 'light' | 'dark'

@Injectable({
  providedIn: 'root'
})
export class ThemeModeService {
  public isDarkMode = new BehaviorSubject<boolean>(
    !this.userDarkModeType() ? this.isSystemDarkMode() : this.userDarkModeType() === 'dark'
  )

  constructor() {
    this.changeDarkMode(this.userDarkModeType())
    this.changeColorPalette(this.userColorPalette())
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      this.changeDarkMode(this.userDarkModeType())
    })
  }

  changeDarkMode(type?: DarkModeType | null) {
    if (window.location.href.includes(environment.adminUrl)) {
      localStorage.removeItem(LOCALSTORAGE_KEYS.DARK_MODE)
      document.body.classList.remove('dark')
      return
    }

    if (!type) {
      localStorage.removeItem(LOCALSTORAGE_KEYS.DARK_MODE)
      if (this.isSystemDarkMode()) {
        document.body.classList.add('dark')
      } else {
        document.body.classList.remove('dark')
      }
    } else {
      localStorage.setItem(LOCALSTORAGE_KEYS.DARK_MODE, type)
      switch (type) {
        case 'dark':
          document.body.classList.add('dark')
          break

        default:
          document.body.classList.remove('dark')
          break
      }
    }

    this.isDarkMode.next(document.body.classList.contains('dark'))
  }

  changeColorPalette(paletteIndex?: string | null) {
    Array(5)
      .fill(1)
      .forEach((el, index) => {
        document.body.classList.remove(`palette${index + 1}`)
      })
    if (!paletteIndex || paletteIndex === '1') {
      localStorage.removeItem(LOCALSTORAGE_KEYS.COLOR_PALETTE)
    } else {
      localStorage.setItem(LOCALSTORAGE_KEYS.COLOR_PALETTE, paletteIndex)
      document.body.classList.add(`palette${paletteIndex}`)
    }

    this.isDarkMode.next(document.body.classList.contains('dark'))
  }

  public cssvar(varName: string) {
    return getComputedStyle(document.body).getPropertyValue(varName)
  }

  public userDarkModeType() {
    return localStorage.getItem(LOCALSTORAGE_KEYS.DARK_MODE) as DarkModeType
  }

  public userColorPalette() {
    return localStorage.getItem(LOCALSTORAGE_KEYS.COLOR_PALETTE)
  }

  isSystemDarkMode() {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  }
}
