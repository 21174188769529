<mg-page-title [title]="title" [subTitle]="subTitle">
  <mg-filter-form
    [ngClass]="{
      'w-0 h-0 overflow-hidden': selectedTab === 'compTrends',
      'flex-1 w-full': selectedTab !== 'compTrends'
    }"
    [filterFields]="{
      shop: true,
      compareWith: selectedTab !== 'goals'
    }"
    [debounceTime]="0"
    [hiddenCompareWithSections]="hiddenCompareWithSections"
    [showOrganizationAverage]="showOrganizationAverage"
    [showGroup]="showGroup"
    (formValueChanged)="onFilterChanged($event)"
  ></mg-filter-form>
</mg-page-title>

<div *ngIf="showVerifiedBanner; else contentTemplate">
  <mg-no-report-banner class="mt-2xl w-full" variant="shopNotVerified"></mg-no-report-banner>
</div>

<ng-template #loadingTemplate>
  <div #lodaingSlot>
    <ng-content select="[slot=loading]"></ng-content>
  </div>
  <div *ngIf="!lodaingSlot.children.length" class="mt-2xl flex justify-center">
    <mat-progress-spinner [diameter]="80" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
