import { IBaseRequest } from '@mg-platform/core/core-data-access'

export class GetTrends {
  static readonly type = '[TrendReports] Get Trends'
  constructor(public payload: IBaseRequest) {}
}

export class UpdateTrendsInterval {
  static readonly type = '[TrendReports] Update Trends Interval'
  constructor(public trendsCustomInterval: number) {}
}

export class DeleteTrendsInterval {
  static readonly type = '[TrendReports] Delete Trends Interval'
}

export class GetTireTrends {
  static readonly type = '[TrendReports] Get Tire Trends'
  constructor(public payload: IBaseRequest) {}
}

export class UpdateTireTrendsInterval {
  static readonly type = '[TrendReports] Update Tire Trends Interval'
  constructor(public trendsCustomInterval: number) {}
}

export class DeleteTireTrendsInterval {
  static readonly type = '[TrendReports] Delete Tire Trends Interval'
}
