<div class="bg-contrast h-full w-full relative p-lg overflow-auto">
  <!-- Close Btn -->
  <div class="absolute cursor-pointer top-lg right-2xl">
    <svg-icon color="#6B7980" key="close" class="!text-base" (click)="dialogRef.close()"></svg-icon>
  </div>

  <mg-week-selector
    [availableWeeks]="data.availableWeeks"
    [selectedWeekNumber]="data.selectedWeekNumber"
    (selectedWeekChanged)="dialogRef.close($event)"
  ></mg-week-selector>
</div>
