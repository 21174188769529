<div class="w-full flex h-[100dvh] overflow-hidden">
  <div class="flex-1 relative bg-primary h-full hidden md:block">
    <div class="logo-section h-full flex items-center justify-center">
      <img width="284px" src="assets/images/icons/big-logo-white.svg" />
    </div>

    <div
      class="absolute flex bottom-2xl left-2xl p-lg text-white cursor-pointer"
      (click)="themeModeService.changeDarkMode(isDarkMode ? 'light' : 'dark')"
    >
      <svg-icon [key]="isDarkMode ? 'dark-mode' : 'light-mode'" class="!text-lg"></svg-icon>
    </div>
  </div>
  <div class="flex-1 bg-contrast h-full overflow-auto">
    <div class="md:max-w-[390px] md:mx-auto h-full md:py-2xl md:px-lg">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
