export const ORGANIZATION_AVERAGE = {
  id: '279c8532-13df-4a0b-b1c2-c48b4af692bc',
  name: 'My Organization'
}

export const PAGE_SUBTITLES = {
  weeklySummary: 'Select your shop and date range to see your shop stats and performance indicators.',
  marketPricing: 'Update your pricing when you make price changes.',
  weeklyTrends: 'Compare your shop’s trends against other shops, states, or regions.',
  tireTrends: 'Compare your shop’s tire sales trends against national tire sales trends',
  benchmark: 'Compare your shop benchmarks to other shops, states, or regions.',
  goals: 'Select your shop and confirm the number of working days you have this month.',
  compSales: 'Your shop’s monthly sales compared to the same period last year.',
  salesTracker: 'Track your shop’s sales performance over time.',
  grossProfit: 'Track and optimize your profit margins.',
  rankings: 'View and compare your shop rankings'
}
