import { Component, Input, OnInit } from '@angular/core'
import { CountryCode, FiltersState } from '@mg-platform/core/core-data-access'
import { MarketType } from '@mg-platform/reports/reports-data-access'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'

@UntilDestroy()
@Component({
  selector: 'mg-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent implements OnInit {
  @Input() countryId: string
  @Input() marketType: MarketType
  @Input() countryCode: CountryCode
  @Input() hidenCountry: CountryCode

  marketTypes = MarketType
  flag = 'usa'

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.countryCode && this.countryId) {
      const allCountries = this.store.selectSnapshot(FiltersState.allCountries)
      this.countryCode = allCountries.find((el) => el.value === this.countryId)?.extraInfo
    }
    if (this.countryCode === CountryCode.CA) {
      this.flag = 'ca'
    }
  }
}
