import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { UsersService } from '../../users.service'
import {
  AnswerInvitation,
  CheckInvitedUser,
  DeleteInvitation,
  GetInvitationInfo,
  SendInvitation,
  SetInvitedUserInfo
} from './user-invitation.actions'
import { UserInvitationStateModel } from './user-invitation.model'

@State<UserInvitationStateModel>({
  name: 'userInvitation'
})
@Injectable()
export class UserInvitationState {
  constructor(private usersService: UsersService) {}

  @Selector()
  static invitationInfo(state: UserInvitationStateModel) {
    return state.invitationInfo
  }

  @Selector()
  static isUserExist(state: UserInvitationStateModel) {
    return state.isUserExist
  }

  @Selector()
  static invitedUserInfo(state: UserInvitationStateModel) {
    return state.invitedUserInfo
  }

  @Action(SendInvitation)
  sendInvitation(ctx: StateContext<UserInvitationStateModel>, { payload }: SendInvitation) {
    return this.usersService.sendInvitation(payload)
  }

  @Action(GetInvitationInfo)
  getInvitationInfo(ctx: StateContext<UserInvitationStateModel>, { token }: GetInvitationInfo) {
    return this.usersService.getInvitationInfo(token).pipe(
      tap((res) => {
        ctx.patchState({ invitationInfo: res })
      })
    )
  }

  @Action(CheckInvitedUser)
  checkInvitedUser(ctx: StateContext<UserInvitationStateModel>, { token }: CheckInvitedUser) {
    return this.usersService.checkInvitedUser(token).pipe(
      tap((res) => {
        ctx.patchState({ isUserExist: res.isUserExist })
      })
    )
  }

  @Action(SetInvitedUserInfo)
  setInvitedUserInfo(ctx: StateContext<UserInvitationStateModel>, { payload }: SetInvitedUserInfo) {
    ctx.patchState({ invitedUserInfo: payload })
  }

  @Action(AnswerInvitation)
  answerInvitation(ctx: StateContext<UserInvitationStateModel>, { payload }: AnswerInvitation) {
    return this.usersService.answerInvitation(payload)
  }

  @Action(DeleteInvitation)
  deleteInvitation(ctx: StateContext<UserInvitationStateModel>, { id }: DeleteInvitation) {
    return this.usersService.deleteInvitation(id)
  }
}
