import { IBaseRequest, IPaginationRequest } from '@mg-platform/core/core-data-access'
import { IMarketPricingRequest } from '../../interfaces/market-pricings.interface'

export class GetMarketPricing {
  static readonly type = '[MarketPricingReports] Get Market Pricing'
  constructor(public payload: IBaseRequest) {}
}

export class ResetMarketPricing {
  static readonly type = '[MarketPricingReports] Reset Market Pricing'
}

export class UpdateMarketPricing {
  static readonly type = '[MarketPricingReports] Update Market Pricing'
  constructor(public payload: IMarketPricingRequest, public shopId: string) {}
}

export class AdminGetMarketPricing {
  static readonly type = '[MarketPricingReports] Admin Get Market Pricing'
  constructor(public paginatedRequest: IPaginationRequest) {}
}

export class AdminUpdateMarketPricing {
  static readonly type = '[MarketPricingReports] Admin Update Market Pricing'
  constructor(public payload: IMarketPricingRequest, public shopId: string) {}
}

export class MarketPricingStatusChanged {
  static readonly type = '[MarketPricingReports] Market Pricing Status Changed'
  constructor(public payload: boolean) {}
}

export class GetMarketPricingThresholds {
  static readonly type = '[MarketPricingReports] Get Market Pricing Thresholds'
}
