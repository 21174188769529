import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { EnterpriseReportsService } from '../../enterprise-reports.service'
import { GetEnterpriseMarketPricings } from './enterprise-market-pricings.actions'
import { EnterpriseMarketPricingsStateModel } from './enterprise-market-pricings.model'

@State<EnterpriseMarketPricingsStateModel>({
  name: 'enterpriseMarketPricings'
})
@Injectable()
export class EnterpriseMarketPricingsState {
  constructor(private enterpriseReportsService: EnterpriseReportsService) {}

  @Selector()
  static marketPricings(state: EnterpriseMarketPricingsStateModel) {
    return state.marketPricings
  }

  @Action(GetEnterpriseMarketPricings)
  getEnterpriseMarketPricings(
    ctx: StateContext<EnterpriseMarketPricingsStateModel>,
    { payload }: GetEnterpriseMarketPricings
  ) {
    return this.enterpriseReportsService.getMarketPricings(payload).pipe(
      tap((res) => {
        ctx.patchState({ marketPricings: res })
      })
    )
  }
}
