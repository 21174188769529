import { Injectable } from '@angular/core'
import {
  convertPaginationRequest,
  HttpService,
  IBaseRequest,
  ICompareWithSelection,
  IExtendedRequest,
  IPaginationRequest,
  ITimeBaseRequest
} from '@mg-platform/core/core-data-access'
import { DateService } from '@mg-platform/core/core-util'
import { Observable } from 'rxjs'
import { IAvailableWeeksReponse } from './interfaces/available-weeks.interface'
import { IBenchmarkResponse } from './interfaces/benchmark.interface'
import { ICalendarBenchmarkResponse } from './interfaces/calendar.interface'
import {
  ICompTrendsReportRequest,
  ICompTrendsReportResponse
} from './interfaces/comp-trends-report.interface'
import { ICompareResponse } from './interfaces/compare-report.interface'
import {
  IAddDailyReportRequest,
  IDailyReportResponse,
  IMissingDaysResponse,
  IUpdateDailyReportRequest
} from './interfaces/daily-report.interface'
import {
  IMarketPricingRequest,
  IMarketPricingResponse,
  IMarketPricingThresholds
} from './interfaces/market-pricings.interface'
import {
  IWeeklyReportMonthlyGoals,
  IWeeklyReportPreviousFourthWeeks,
  IWeeklyReportRequest,
  IWeeklyReportSales
} from './interfaces/weekly-report.interface'
import { REPORTS_API_URL } from './reports-api-routes'
import { IPublicWeeklyReportResponse } from './interfaces/public-weekly-report.interface'
import { IAverageTrendResponse } from './interfaces/average-trends.interface'
import { ISalesReportResponse } from './interfaces/sales-report.interface'
import {
  IGrossProfitByTagSizeResponse,
  IGrossprofitReportResponse
} from './interfaces/grossprofit-report.interface'
import { IAdminMarketPricingResponse } from './interfaces/admin-market-pricings.interface'
import { IAverageTireTrendsResponse } from './interfaces/average-tire-trends.interface'
import { IRankingResponse } from './interfaces/rankings-report.interface'

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private http: HttpService, private dateService: DateService) {}

  benchmarks(payload: IExtendedRequest): Observable<IBenchmarkResponse> {
    return this.http.post<IBenchmarkResponse>(REPORTS_API_URL.benchmarks, {
      ...payload,
      startDate: payload?.startDate ? this.dateService.momentToDateString(payload.startDate) : undefined,
      endDate: payload?.endDate ? this.dateService.momentToDateString(payload.endDate) : undefined
    })
  }

  calendar(shopId: string): Observable<ICalendarBenchmarkResponse[]> {
    return this.http.post<ICalendarBenchmarkResponse[]>(REPORTS_API_URL.calendar(shopId), {})
  }

  getOneDailyReport(shopId: string, dailyReportId: string): Observable<IDailyReportResponse> {
    return this.http.get<IDailyReportResponse>(REPORTS_API_URL.getOneDailyReport(shopId, dailyReportId))
  }

  addDailyReport(payload: IAddDailyReportRequest[]) {
    return this.http.post(REPORTS_API_URL.addDailyReport, payload)
  }

  updateDailyReport(payload: IUpdateDailyReportRequest, shopId: string) {
    return this.http.put(REPORTS_API_URL.updateDailyReport(shopId), payload)
  }

  getMissingDaysReports(): Observable<IMissingDaysResponse[]> {
    return this.http.get<IMissingDaysResponse[]>(REPORTS_API_URL.getMissingDaysReports)
  }

  getMarketPricing(payload: IBaseRequest): Observable<IMarketPricingResponse> {
    return this.http.post<IMarketPricingResponse>(REPORTS_API_URL.getMarketPricing, payload)
  }

  updateMarketPricing(payload: IMarketPricingRequest, shopId: string) {
    return this.http.post(REPORTS_API_URL.updateMarketPricing(shopId), payload)
  }

  adminGetMarketPricing(paginatedRequest: IPaginationRequest): Observable<IAdminMarketPricingResponse> {
    return this.http.get<IAdminMarketPricingResponse>(REPORTS_API_URL.adminGetMarketPricing, {
      params: { ...convertPaginationRequest(paginatedRequest) }
    })
  }

  adminUpdateMarketPricing(payload: IMarketPricingRequest, shopId: string) {
    return this.http.put(REPORTS_API_URL.adminUpdateMarketPricing(shopId), payload)
  }

  getMarketPricingThresholds(): Observable<IMarketPricingThresholds> {
    return this.http.get(REPORTS_API_URL.getMarketPricingThresholds)
  }

  getTrends(payload: IBaseRequest): Observable<IAverageTrendResponse> {
    return this.http.post<IAverageTrendResponse>(REPORTS_API_URL.getTrends, payload)
  }

  updateTrendsInterval(trendsCustomInterval: number) {
    return this.http.post(REPORTS_API_URL.updateTrendsInterval, { trendsCustomInterval })
  }

  deleteTrendsInterval() {
    return this.http.delete(REPORTS_API_URL.updateTrendsInterval)
  }

  getTireTrends(payload: IBaseRequest): Observable<IAverageTireTrendsResponse> {
    return this.http.post<IAverageTireTrendsResponse>(REPORTS_API_URL.getTireTrends, payload)
  }

  updateTireTrendsInterval(trendsCustomInterval: number) {
    return this.http.post(REPORTS_API_URL.updateTireTrendsInterval, { trendsCustomInterval })
  }

  deleteTireTrendsInterval() {
    return this.http.delete(REPORTS_API_URL.updateTireTrendsInterval)
  }

  getWeeklyReportsSales(payload: IWeeklyReportRequest): Observable<IWeeklyReportSales> {
    return this.http.post<IWeeklyReportSales>(REPORTS_API_URL.weeklySummarySales, payload)
  }

  getWeeklyReportsCompSales(payload: IWeeklyReportRequest): Observable<ICompareResponse> {
    return this.http.post<ICompareResponse>(REPORTS_API_URL.weeklySummaryCompSales, payload)
  }

  getWeeklyReportsMonthlyGoals(payload: IWeeklyReportRequest): Observable<IWeeklyReportMonthlyGoals> {
    return this.http.post<IWeeklyReportMonthlyGoals>(REPORTS_API_URL.weeklySummaryMonthlyGoals, payload)
  }

  getWeeklyReportsAverageTrends(payload: IWeeklyReportRequest): Observable<IAverageTrendResponse> {
    return this.http.post<IAverageTrendResponse>(REPORTS_API_URL.weeklySummaryAverageTrends, payload)
  }

  getWeeklyReportsPreviousFourthWeeks(
    payload: IWeeklyReportRequest
  ): Observable<IWeeklyReportPreviousFourthWeeks> {
    return this.http.post<IWeeklyReportPreviousFourthWeeks>(
      REPORTS_API_URL.weeklySummaryPreviousFourthWeeks,
      payload
    )
  }

  getAvailableWeeks(): Observable<IAvailableWeeksReponse> {
    return this.http.get<IAvailableWeeksReponse>(REPORTS_API_URL.weeklySummaryBase)
  }

  getPublicWeeklyReports(token: string): Observable<IPublicWeeklyReportResponse> {
    return this.http.post<IPublicWeeklyReportResponse>(REPORTS_API_URL.getPublicWeeklyReports, {
      token
    })
  }

  getCompareReports(payload: Partial<ICompareWithSelection>): Observable<ICompareResponse> {
    return this.http.post<ICompareResponse>(REPORTS_API_URL.getCompareReports, payload)
  }

  getCompareAllShopsReports(payload: Partial<ICompareWithSelection>): Observable<ICompareResponse> {
    return this.http.post<ICompareResponse>(REPORTS_API_URL.getCompareAllShopsReports, payload)
  }

  getCompTrends(payload: ICompTrendsReportRequest): Observable<ICompTrendsReportResponse> {
    return this.http.post<ICompTrendsReportResponse>(REPORTS_API_URL.getComptrends, payload)
  }

  getSalesReports(payload: Partial<ICompareWithSelection>): Observable<ISalesReportResponse> {
    return this.http.post<ISalesReportResponse>(REPORTS_API_URL.getSalesReports, payload)
  }

  getGrossprofitReports(payload: Partial<IExtendedRequest>): Observable<IGrossprofitReportResponse> {
    return this.http.post<IGrossprofitReportResponse>(REPORTS_API_URL.getGrossProfitReports, {
      ...payload,
      startDate: payload?.startDate ? this.dateService.momentToDateString(payload.startDate) : undefined,
      endDate: payload?.endDate ? this.dateService.momentToDateString(payload.endDate) : undefined
    })
  }

  getGrossProfitByTagSize(payload: ITimeBaseRequest): Observable<IGrossProfitByTagSizeResponse> {
    return this.http.post<IGrossProfitByTagSizeResponse>(
      REPORTS_API_URL.getGrossProfitByTagSize,
      payload
    )
  }

  getRankingsReports(payload: ITimeBaseRequest): Observable<IRankingResponse> {
    return this.http.post<IRankingResponse>(REPORTS_API_URL.getRankingsReports, {
      startDate: payload?.startDate ? this.dateService.momentToDateString(payload.startDate) : undefined,
      endDate: payload?.endDate ? this.dateService.momentToDateString(payload.endDate) : undefined
    })
  }
}
