import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { AuthLayoutComponent } from './auth-layout/auth-layout.component'

@NgModule({
  declarations: [AuthLayoutComponent],
  imports: [CommonModule, RouterModule, SvgIconsModule],
  exports: [AuthLayoutComponent]
})
export class AuthLayoutModule {}
