import { IExtendedRequest, ITimeBaseRequest } from '@mg-platform/core/core-data-access'

export class GetGrossprofitReports {
  static readonly type = '[GrossprofitReports] Get Grossprofit Reports'
  constructor(public payload: Partial<IExtendedRequest>) {}
}

export class GetGrossProfitByTagSize {
  static readonly type = '[GrossprofitReports] Get Gross Profit By Tag Size'
  constructor(public payload: ITimeBaseRequest) {}
}
