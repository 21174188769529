import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import { GrossprofitReportsStateModel } from './grossprofit-reports.model'
import { GetGrossProfitByTagSize, GetGrossprofitReports } from './grossprofit-reports.actions'
import { IGrossProfitByTagSizeExtended } from '../../interfaces/grossprofit-report.interface'
import { ShopTagSize } from '../../enums/shop-tag-size.enum'

@State<GrossprofitReportsStateModel>({
  name: 'grossprofitReports'
})
@Injectable()
export class GrossprofitReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static reports(state: GrossprofitReportsStateModel) {
    return state.reports
  }

  @Selector()
  static grossProfitByTagSize(
    state: GrossprofitReportsStateModel
  ): IGrossProfitByTagSizeExtended | undefined {
    if (!state.grossProfitByTagSize) {
      return
    }
    const { grossProfitByTagSize } = state
    return {
      nationalValue: grossProfitByTagSize.nationalValue,
      LessThanMillion: grossProfitByTagSize?.items?.find(
        (el) => el.tagSize === ShopTagSize.LessThanMillion
      ),
      BetweenMillionAndTwoMillion: grossProfitByTagSize?.items?.find(
        (el) => el.tagSize === ShopTagSize.BetweenMillionAndTwoMillion
      ),
      OverTwoMillion: grossProfitByTagSize?.items?.find(
        (el) => el.tagSize === ShopTagSize.OverTwoMillion
      )
    }
  }

  @Action(GetGrossprofitReports)
  getGrossprofitReports(
    ctx: StateContext<GrossprofitReportsStateModel>,
    { payload }: GetGrossprofitReports
  ) {
    return this.reportsService.getGrossprofitReports(payload).pipe(
      tap((res) => {
        ctx.patchState({ reports: res })
      })
    )
  }

  @Action(GetGrossProfitByTagSize)
  getGrossProfitByTagSize(
    ctx: StateContext<GrossprofitReportsStateModel>,
    { payload }: GetGrossProfitByTagSize
  ) {
    return this.reportsService.getGrossProfitByTagSize(payload).pipe(
      tap((res) => {
        ctx.patchState({ grossProfitByTagSize: res })
      })
    )
  }
}
