import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AutofocusDirective } from './auto-focus.directive'
import { IsVisibleDirective } from './is-visible.directive'
import { EnterViewportDirective } from './enter-viewport.directive'

@NgModule({
  declarations: [AutofocusDirective, IsVisibleDirective, EnterViewportDirective],
  imports: [CommonModule],
  exports: [AutofocusDirective, IsVisibleDirective, EnterViewportDirective]
})
export class DirectivesModule {}
