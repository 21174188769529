import { Injectable } from '@angular/core'
import { ShopsService } from '../../shops.service'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import {
  AdminDeclineShop,
  AdminVerifyShop,
  GetAdminShops,
  GetOneAdminShop,
  UpdateAdminShop,
  AdminDeleteShopUser,
  GetAdminDailyReportsShops,
  SetAdminShopPosType,
  AdminDeleteShop
} from './admin-shops.actions'
import { AdminShopsStateModel } from './admin-shops.model'
import { IPaginationResponse } from '@mg-platform/core/core-data-access'

@State<AdminShopsStateModel>({
  name: 'adminShops'
})
@Injectable()
export class AdminShopsState {
  constructor(private shopsService: ShopsService) {}

  @Selector()
  static shop(state: AdminShopsStateModel) {
    return state.shop
  }

  static shopUsers(state: AdminShopsStateModel) {
    return state.shop?.users ?? []
  }

  @Selector()
  static allShops(state: AdminShopsStateModel) {
    return state.allShops
  }

  @Selector()
  static verifiedPaginationInfo(state: AdminShopsStateModel) {
    if (!state.verifiedPaginationInfo) {
      return
    }
    return this.getFullPaginationInfo(state.verifiedPaginationInfo)
  }

  @Selector()
  static pendingPaginationInfo(state: AdminShopsStateModel) {
    if (!state.pendingPaginationInfo) {
      return
    }
    return this.getFullPaginationInfo(state.pendingPaginationInfo)
  }

  @Selector()
  static ignoredPaginationInfo(state: AdminShopsStateModel) {
    if (!state.ignoredPaginationInfo) {
      return
    }
    return this.getFullPaginationInfo(state.ignoredPaginationInfo)
  }

  @Action(GetAdminShops, { cancelUncompleted: true })
  getAdminShops(ctx: StateContext<AdminShopsStateModel>, { paginatedRequest, type }: GetAdminShops) {
    ctx.patchState({
      shop: undefined
    })
    switch (type) {
      case 'pending':
        return this.shopsService.getAdminPendingShops(paginatedRequest).pipe(
          tap((res) => {
            ctx.patchState({
              allShops: res.shops,
              pendingPaginationInfo: res.pagination
            })
          })
        )

      case 'ignored':
        return this.shopsService.getAdminIgnoredShops(paginatedRequest).pipe(
          tap((res) => {
            ctx.patchState({
              allShops: res.shops,
              ignoredPaginationInfo: res.pagination
            })
          })
        )

      default:
        return this.shopsService.getAllVerifiedShops(paginatedRequest).pipe(
          tap((res) => {
            ctx.patchState({
              allShops: res.shops,
              verifiedPaginationInfo: res.pagination
            })
          })
        )
    }
  }

  @Action(GetOneAdminShop)
  getOneAdminShop(ctx: StateContext<AdminShopsStateModel>, { shopId }: GetOneAdminShop) {
    return this.shopsService.getOneAdminShop(shopId).pipe(
      tap((res) => {
        ctx.patchState({ shop: res })
      })
    )
  }

  @Action(UpdateAdminShop)
  updateAdminShop(ctx: StateContext<AdminShopsStateModel>, { payload, shopId }: UpdateAdminShop) {
    return this.shopsService.updateAdminShop(payload, shopId)
  }

  @Action(SetAdminShopPosType)
  setAdminShopPosType(ctx: StateContext<AdminShopsStateModel>, { posType }: SetAdminShopPosType) {
    const shop = ctx.getState()?.shop
    if (shop) {
      ctx.patchState({
        shop: {
          ...shop,
          posType
        }
      })
    }
  }

  @Action(AdminVerifyShop)
  adminVerifyShop(ctx: StateContext<AdminShopsStateModel>, { shopId }: AdminVerifyShop) {
    return this.shopsService.adminVerifyShop(shopId)
  }

  @Action(AdminDeclineShop)
  adminDeclineShop(ctx: StateContext<AdminShopsStateModel>, { shopId }: AdminDeclineShop) {
    return this.shopsService.adminDeclineShop(shopId)
  }

  @Action(AdminDeleteShop)
  adminDeleteShop(ctx: StateContext<AdminShopsStateModel>, { shopId }: AdminDeleteShop) {
    return this.shopsService.adminDeleteShop(shopId)
  }

  @Action(AdminDeleteShopUser)
  adminDeleteShopUser(ctx: StateContext<AdminShopsStateModel>, { shopId, userId }: AdminDeleteShopUser) {
    return this.shopsService.adminDeleteShopUser(shopId, userId)
  }

  @Action(GetAdminDailyReportsShops)
  getAdminDailyReportsShops(
    ctx: StateContext<AdminShopsStateModel>,
    { payload, excludeBatch }: GetAdminDailyReportsShops
  ) {
    return this.shopsService.getDailyReportsShops(payload, excludeBatch).pipe(
      tap((res) => {
        ctx.patchState({
          allShops: res.shops
        })
      })
    )
  }

  private static getFullPaginationInfo(info: IPaginationResponse) {
    return {
      ...info,
      totalPages: info.totalResults / info.pageSize + 1
    }
  }
}
