import { UntypedFormControl } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'

export class NotSameErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    const controlIntracted = !!(control?.touched || control?.dirty)
    const invalidCtrl = controlIntracted && control?.invalid
    const invalidParent = controlIntracted && (control?.parent?.hasError('notSame') ?? false)

    return invalidCtrl || invalidParent
  }
}
