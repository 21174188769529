import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core'
import { ControlContainer, UntypedFormControl } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatFormField, MatFormFieldAppearance } from '@angular/material/form-field'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { debounceTime } from 'rxjs'
import { ISelectItem } from './input.interface'
import { startOfDay, subDays } from 'date-fns'
import { NormalErrorMatcher } from '@mg-platform/core/core-util'
import { CountryCode } from '@mg-platform/core/core-data-access'

interface IInputErrorMessages {
  type: string
  message: string
}

const globalErrorMessages: IInputErrorMessages[] = [
  {
    type: 'required',
    message: 'This field is required'
  },
  {
    type: 'email',
    message: 'Please enter a valid email'
  }
]

@UntilDestroy()
@Component({
  selector: 'mg-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnChanges, AfterViewChecked, AfterViewInit {
  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>

  @Input() normalInputId: string
  @Input() controlName: string
  @Input() label: string
  @Input() prefix: string
  @Input() suffix = ''
  @Input() noPadding = false
  @Input() size: 'small' | 'medium' = 'medium'
  @Input() variant: 'default' | 'secondary' | 'white' | 'contrast' = 'default'
  @Input() appearance: MatFormFieldAppearance = 'outline'
  @Input() mask: string
  @Input() useCurrencyMask = false
  @Input() debounceDuration = 0
  @Input() type:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'select'
    | 'date'
    | 'google-places'
    | 'textarea' = 'text'
  @Input() errorMessages: IInputErrorMessages[] = []
  @Input() customErrorMatcher: ErrorStateMatcher
  @Input() customError: {
    show: boolean
    message: string
  }
  @Input() autoFocus = false
  @Input() loading = false
  @Input() disabled = false
  @Input() googlePlacesCountries: CountryCode[]
  @Input() items: ISelectItem[] = []
  @Input() icon: string
  @Input() iconColor = '#808080'
  @Input() clearIconColor = '#808080'
  @Input() iconClass = ''
  @Input() iconSize = '14px'
  @Input() clearIconSize = '12px'
  @Input() minTextAreaRows = 7
  @Input() maxTextAreaRows = 10
  @Input() extraSelectPanelClass = ''
  @Input() extraInputClass = ''
  @Input() rightAlign = false
  @Input() normalInputCenter = false
  @Input() placeholder = ''
  @Input() inputmode: 'numeric' | undefined
  @Input() clearable = false
  @Input() maxDate? = subDays(startOfDay(new Date()), 1)

  @Output() valueChanged = new EventEmitter()
  @Output() googlePlaceAddressChanged = new EventEmitter()
  @Output() iconClicked = new EventEmitter()
  @Output() focused = new EventEmitter()
  @Output() blured = new EventEmitter()

  formControl: UntypedFormControl
  minimizeLabel = false
  allErrorMessages: IInputErrorMessages[] = []
  showPassword = false
  inputValue: string | null

  errorMatcher = new NormalErrorMatcher()

  constructor(
    private controlContainer: ControlContainer,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.controlName) as UntypedFormControl

    this.allErrorMessages = [...this.errorMessages, ...globalErrorMessages]
    this.formControl?.valueChanges
      .pipe(untilDestroyed(this), debounceTime(this.debounceDuration))
      .subscribe((value) => {
        this.valueChanged.emit(value)
      })
  }

  ngOnChanges() {
    if (this.loading || this.disabled) {
      this.formControl?.disable()
    } else {
      this.formControl?.enable()
    }
  }

  ngAfterViewInit(): void {
    if (this.type === 'google-places') {
      const input = document.getElementById('google-places-input') as HTMLInputElement
      if (input) {
        const autocomplete = new google.maps.places.Autocomplete(input, {
          types: ['establishment'],
          componentRestrictions: { country: this.googlePlacesCountries }
        })
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          this.googlePlaceAddressChanged.emit(autocomplete.getPlace())
          input.focus()
        })
      }
    }
  }

  ngAfterViewChecked(): void {
    // Fix mat select error when the option list is empty
    if (this.type === 'select') {
      this.changeDetectorRef.detectChanges()
    }
  }

  handleErrorMessage() {
    if (this.customError?.show && this.customError?.message) {
      return this.customError.message
    }
    const allErrors: any = this.formControl?.errors ?? []
    return this.allErrorMessages.find((el) => allErrors[el.type])?.message ?? ''
  }

  isFormControlInvalid() {
    return (
      (this.customError?.show || this.formControl?.invalid) &&
      (this.formControl?.dirty || this.formControl?.touched)
    )
  }
}
