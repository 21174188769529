import { Injectable } from '@angular/core'
import {
  convertPaginationRequest,
  HttpService,
  IPaginationRequest
} from '@mg-platform/core/core-data-access'
import { Observable, throwError } from 'rxjs'
import { IUserRegisterRequest } from './interfaces/user-register.interface'
import { IUserLoginAutovitalsRequest, IUserLoginRequest } from './interfaces/user-login.interface'
import { ITokenResponse, IUser } from './interfaces/user.interface'
import { USERS_API_URL } from './users-api-routes'
import {
  IRequestPasswordReset,
  IResetPassword,
  IUpdatePasswordRequest
} from './interfaces/password.interface'
import {
  IHandleInvitationRequest,
  IInvitationResponse,
  IInvitedUserStatus,
  IInviteUserRequest
} from './interfaces/invitation.interface'
import { IUpdateProfileRequest } from './interfaces/update-profile.interface'
import { IUserPreferences } from './interfaces/user-preferences.interface'
import {
  IAdminAllUsersResponse,
  IAdminUserDetails,
  IUpdateAdminUserRequest
} from './interfaces/admin-users.interface'
import { LOCALSTORAGE_KEYS } from '@mg-platform/core/core-util'

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpService) {}

  login(payload: IUserLoginRequest): Observable<ITokenResponse> {
    return this.http.post(USERS_API_URL.login, payload)
  }

  loginAutovitals(payload: IUserLoginAutovitalsRequest) {
    return this.http.post(USERS_API_URL.loginAutovitals, payload)
  }

  logout() {
    return this.http.post(USERS_API_URL.logout, {})
  }

  refreshToken(): Observable<ITokenResponse> {
    const associatedBearerToken = localStorage.getItem(LOCALSTORAGE_KEYS.JWT_TOKEN)
    if (associatedBearerToken) {
      return this.http.post(USERS_API_URL.refreshToken, {
        associatedBearerToken
      })
    }
    return throwError(() => 'Error')
  }

  getFullUserInformation(): Observable<IUser> {
    return this.http.get<IUser>(USERS_API_URL.me)
  }

  register(payload: IUserRegisterRequest): Observable<ITokenResponse> {
    return this.http.post(USERS_API_URL.register, payload)
  }

  requestPasswordReset(payload: IRequestPasswordReset) {
    return this.http.post(USERS_API_URL.requestPasswordReset, payload)
  }

  resetPassword(payload: IResetPassword): Observable<ITokenResponse> {
    return this.http.post(USERS_API_URL.resetPassword, payload)
  }

  updateProfile(payload: IUpdateProfileRequest) {
    return this.http.put(USERS_API_URL.updateProfile, payload)
  }

  updatePassword(payload: IUpdatePasswordRequest) {
    return this.http.put(USERS_API_URL.updatePassword, payload)
  }

  sendInvitation(payload: IInviteUserRequest) {
    return this.http.post(USERS_API_URL.sendInvitation, payload)
  }

  getInvitationInfo(token: string): Observable<IInvitationResponse> {
    return this.http.get<IInvitationResponse>(USERS_API_URL.getInvitationInfo(token))
  }

  answerInvitation(payload: IHandleInvitationRequest) {
    return this.http.post(USERS_API_URL.answerInvitation, payload)
  }

  checkInvitedUser(token: string): Observable<IInvitedUserStatus> {
    return this.http.get<IInvitedUserStatus>(USERS_API_URL.checkInvitedUser(token))
  }

  deleteInvitation(id: string) {
    return this.http.delete(USERS_API_URL.deleteInvitation(id))
  }

  updateUserPreferences(payload: IUserPreferences) {
    return this.http.put(USERS_API_URL.preferences, payload)
  }

  getUserPreferences(): Observable<IUserPreferences> {
    return this.http.get(USERS_API_URL.preferences)
  }

  getAllAdminUsers(paginatedRequest: IPaginationRequest): Observable<IAdminAllUsersResponse> {
    return this.http.get<IAdminAllUsersResponse>(USERS_API_URL.adminUserBase(), {
      params: { ...convertPaginationRequest(paginatedRequest) }
    })
  }

  getOneAdminUser(userId: string): Observable<IAdminUserDetails> {
    return this.http.get<IAdminUserDetails>(USERS_API_URL.adminUserBase(userId))
  }

  updateAdminUser(userId: string, payload: IUpdateAdminUserRequest) {
    return this.http.post(USERS_API_URL.adminUserBase(userId), payload)
  }
}
