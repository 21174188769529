import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-menu-change-mode-dialog',
  templateUrl: './menu-change-mode-dialog.component.html',
  styleUrls: ['./menu-change-mode-dialog.component.scss']
})
export class MenuChangeModeDialogComponent {
  constructor(public dialogRef: MatDialogRef<MenuChangeModeDialogComponent>) {}
}
