export const environment = {
  production: true,
  name: 'app',
  apiUrl: '/api/v2',
  websiteUrl: 'https://my.marketgenie.app',
  adminUrl: 'https://admin.marketgenie.app',
  publicUrl: 'https://marketgenie.app',
  pageSize: 100,
  googlePlacesKey: 'AIzaSyDdkA_2NcJKVMk1PS1dpxFxI3u0hV0uOpo',
  reCaptchaKey: '6Ld0btUgAAAAALmCBtoMMjHBkU2ZvkkhoffyvZgV',
  appInsights: {
    instrumentationKey: '30a70d0c-ccaa-4b77-a621-caf670780ec6'
  },
  googleTagManagerToken: 'GTM-K5NGLNCL'
}
