<div class="hidden print:hidden lg:flex items-center justify-end bg-ui-bg px-2xl py-xs">
  <div
    class="flex p-sm mr-sm text-txt cursor-pointer hover:bg-ui-hover rounded-sm"
    (click)="themeMenuTrigger.openMenu()"
  >
    <svg-icon key="palette" class="!text-lg"></svg-icon>
  </div>
  <div class="mt-lg" [matMenuTriggerFor]="themeMenu" #themeMenuTrigger="matMenuTrigger"></div>

  <div
    class="p-sm flex items-center text-txt cursor-pointer rounded-sm hover:bg-ui-hover"
    (click)="profileMenuTrigger.openMenu()"
  >
    <div class="flex">
      <svg-icon key="user" class="!text-lg"></svg-icon>
    </div>
    <div class="text-base leading-base" *ngIf="currentUser">
      <div class="pl-sm">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
    </div>
  </div>
  <div class="mt-lg" [matMenuTriggerFor]="profileMenu" #profileMenuTrigger="matMenuTrigger"></div>
</div>

<mat-menu
  #themeMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
>
  <div class="flex flex-col overflow-auto w-[300px]" (click)="$event.stopPropagation()">
    <mg-update-theme-card></mg-update-theme-card>
  </div>
</mat-menu>

<mat-menu
  #profileMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
>
  <div class="p-sm group flex flex-col overflow-auto w-[200px]">
    <ng-container *ngFor="let item of profileMenuRoutes">
      <div
        *ngIf="!item.hidden"
        class="menu-item"
        [routerLink]="item.path"
        routerLinkActive="!bg-ui-selected !text-white"
        [routerLinkActiveOptions]="{ exact: !!item.exact }"
      >
        <div class="menu-icon">
          <svg-icon [key]="item.icon" class="!text-lg"></svg-icon>
        </div>
        <div class="menu-item-label">
          <div class="pl-sm">{{ item.label }}</div>
        </div>
      </div>
    </ng-container>
    <div class="menu-item cursor-pointer" (click)="logout()">
      <div class="menu-icon">
        <svg-icon key="logout" class="!text-lg"></svg-icon>
      </div>
      <div class="menu-item-label">
        <div class="pl-sm">Log Out</div>
      </div>
    </div>
  </div>
</mat-menu>
