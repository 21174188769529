import { FilterStateKeys } from './filters.model'

export interface IPageQueryParamsMap {
  [key: string]: FilterStateKeys[]
}

export const MainPageQueryParamsMap: IPageQueryParamsMap = {
  '/market-pricing': ['shopId', 'groupId', 'compareWith'],

  '/weekly-reports': ['shopId', 'groupId', 'weekNumber'],

  '/comp-sales': ['shopId', 'groupId'],

  '/comp-sales/all-shops': ['shopId', 'groupId'],

  '/users/all-users': ['shopId', 'groupId'],

  '/shops/global-targets': ['shopId', 'groupId'],

  '/performance-calendar': ['shopId', 'groupId', 'marketType', 'calendarReportType', 'calendarViewDate'],

  '/goals': ['shopId', 'groupId'],

  '/benchmark': [
    'shopId',
    'groupId',
    'benchmarkSecondCompareWith',
    'benchmarkThirdCompareWith',
    'benchmarkCustomDateInfo',
    'benchmarkExpandStatus'
  ],

  '/weekly-trends': ['shopId', 'groupId', 'compareWith'],

  '/tire-trends': ['shopId', 'groupId', 'compareWith'],

  '/comp-trends': ['compTrendAvgSalesPerBay', 'compTrendAvgCarCount', 'compTrendAvgTicket'],

  '/reports/sales': ['shopId', 'groupId'],

  '/reports/gross-profit': ['shopId', 'groupId', 'grossprofitDateInfo'],

  '/leaderboard': ['rankingsDateInfo']
}

export const AdminPageQueryParamsMap: IPageQueryParamsMap = {
  '/dashboard': ['adminExcludeBatch'],
  '/shops/all': ['adminShopSortState', 'adminShopActiveTab'],
  '/organizations/all': ['adminOrganizationsFilters'],
  '/market-pricing/all': ['adminMarketPricingSortState']
}

export const PersistFilters: FilterStateKeys[] = [
  'adminShopSortState',
  'adminMarketPricingSortState',
  'adminExcludeBatch',
  'benchmarkExpandStatus'
]
