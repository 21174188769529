import { IBenchmarkFilters } from '@mg-platform/reports/reports-data-access'
import { IEnterpiseBenchmarkRequest } from '../../interfaces/enterprise-benchmark.interface'

export class GetEnterpriseBenchmark {
  static readonly type = '[EnterpriseBenchmark] Get Enterprise Benchmark'
  constructor(public payload: IEnterpiseBenchmarkRequest) {}
}

export class SetEnterpriseBenchmarkFilters {
  static readonly type = '[EnterpriseBenchmark] Set Enterprise Benchmark Filters'
  constructor(public payload: IBenchmarkFilters) {}
}
