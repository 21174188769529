export const FullScreenModalConfig = {
  autoFocus: false,
  maxWidth: '100vw',
  maxHeight: '100vh',
  height: '100%',
  width: '100%',
  panelClass: 'fullscreen-modal',
  backdropClass: 'mg-menu-backdrop'
}

export const NormalModalConfig = {
  autoFocus: false,
  panelClass: 'normal-modal',
  backdropClass: 'mg-menu-backdrop'
}

export const MobileMaxWidth = 768
