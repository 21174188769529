<div class="flex flex-col lg:flex-row" *ngIf="currentUser$ | async as currentUser">
  <ng-container *ngIf="shop">
    <mg-autovitals-menu
      *ngIf="shop.isVerified && currentUser.referralType === referralTypes.autovitals"
    ></mg-autovitals-menu>
  </ng-container>

  <div class="flex-1 min-h-screen flex flex-col">
    <div class="flex-1 flex flex-col">
      <div class="lg:hidden print:hidden" [ngSwitch]="currentUser.role">
        <ng-container *ngSwitchCase="allRoles.user">
          <mg-main-header
            [mainMenuRoutes]="userMainRoutes"
            [profileMenuRoutes]="userProfileRoutes"
          ></mg-main-header>
        </ng-container>
        <ng-container *ngSwitchCase="allRoles.enterpriseuser">
          <mg-main-header
            [mainMenuRoutes]="enterpriseMainRoutes"
            [profileMenuRoutes]="enterpriseProfileRoutes"
          ></mg-main-header>
        </ng-container>
      </div>

      <div class="content-container overflow-hidden print:overflow-visible relative">
        <div class="side-menu" [ngSwitch]="currentUser.role">
          <ng-container *ngSwitchCase="allRoles.user">
            <mg-side-menu [mainMenuRoutes]="userMainRoutes"></mg-side-menu>
          </ng-container>
          <ng-container *ngSwitchCase="allRoles.enterpriseuser">
            <mg-side-menu [mainMenuRoutes]="enterpriseMainRoutes"></mg-side-menu>
          </ng-container>
        </div>

        <div id="main-content-container" class="main-content-container">
          <mg-top-menu
            [profileMenuRoutes]="
              currentUser.role === allRoles.enterpriseuser ? enterpriseProfileRoutes : userProfileRoutes
            "
          ></mg-top-menu>
          <div class="main-content beautyScroll">
            <div class="outlet-wrapper">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
