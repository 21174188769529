import { Directive, ElementRef, Input, OnInit } from '@angular/core'

@Directive({
  selector: '[mgAutofocus]'
})
export class AutofocusDirective implements OnInit {
  private focus = true
  constructor(private el: ElementRef) {}

  @Input() set mgAutofocus(condition: boolean) {
    this.focus = condition !== false
  }

  ngOnInit() {
    if (this.focus) {
      setTimeout(() => {
        this.el.nativeElement.focus()
      })
    }
  }
}
