import { Injectable } from '@angular/core'
import {
  convertPaginationRequest,
  HttpService,
  IPaginationRequest
} from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { SHOPS_API_URL } from './shops-api-routes'
import { ICreateShopRequest } from './interfaces/create-shop.interface'
import { IAdminUpdateShopRequest, IUpdateShopRequest } from './interfaces/update-shop.interface'
import { IAdminShop, IShop, IShopResponse } from './interfaces/shop.interface'
import { IShopUsersResponse } from './interfaces/shop-users.interface'
import { IAdminShopsResponse } from './interfaces/admin-shops-response.interface'
import { DateService } from '@mg-platform/core/core-util'
import { IShopFocusRequest } from './interfaces/shop-focus.interface'
import { IUpdateShopGroup } from './interfaces/shop-groups.interface'

@Injectable({
  providedIn: 'root'
})
export class ShopsService {
  constructor(
    private http: HttpService,
    private dateService: DateService
  ) {}

  /*** User Shops ***/

  createShop(payload: ICreateShopRequest): Observable<IShop> {
    return this.http.post<IShop>(SHOPS_API_URL.base(), payload)
  }

  updateShop(payload: IUpdateShopRequest, shopId: string): Observable<IShop> {
    return this.http.put<IShop>(SHOPS_API_URL.base(shopId), payload)
  }

  setShopFocus(payload: IShopFocusRequest, shopId: string) {
    return this.http.put(SHOPS_API_URL.setShopFocus(shopId), payload)
  }

  getAllUserShops(): Observable<IShopResponse> {
    return this.http.get<IShop[]>(SHOPS_API_URL.base())
  }

  getOneUserShops(shopId: string, withLastReportDate?: boolean): Observable<IShop> {
    return this.http.get<IShop>(SHOPS_API_URL.getOneShop(shopId, withLastReportDate))
  }

  getShopUsers(shopId: string): Observable<IShopUsersResponse> {
    return this.http.get<IShopUsersResponse>(SHOPS_API_URL.getShopUsers(shopId))
  }

  getShopPendingUsers(shopId: string): Observable<IShopUsersResponse> {
    return this.http.get<IShopUsersResponse>(SHOPS_API_URL.getShopPendingUsers(shopId))
  }

  deleteShopUser(shopId: string, userId: string) {
    return this.http.delete(SHOPS_API_URL.deleteShopUser(shopId, userId))
  }

  /*** Shop Groups ***/
  updateShopGroup(payload: IUpdateShopGroup) {
    return this.http.post(SHOPS_API_URL.updateShopGroup, payload)
  }

  deleteShopGroup(groupId: string) {
    return this.http.delete(SHOPS_API_URL.deleteShopGroup(groupId))
  }

  autoCreateShopGroups() {
    return this.http.post(SHOPS_API_URL.autoCreateShopGroups, {})
  }

  /*** Admin Shops ***/

  getAllVerifiedShops(paginatedRequest: IPaginationRequest): Observable<IAdminShopsResponse> {
    return this.http.get<IAdminShopsResponse>(SHOPS_API_URL.adminShops(), {
      params: { ...convertPaginationRequest(paginatedRequest), verified: 'true' }
    })
  }

  getOneAdminShop(shopId: string): Observable<IAdminShop> {
    return this.http.get<IAdminShop>(SHOPS_API_URL.adminShops(shopId))
  }

  updateAdminShop(payload: IAdminUpdateShopRequest, shopId: string): Observable<IAdminShop> {
    return this.http.put<IAdminShop>(SHOPS_API_URL.adminShops(shopId), payload)
  }

  getAdminPendingShops(paginatedRequest: IPaginationRequest): Observable<IAdminShopsResponse> {
    return this.http.get<IAdminShopsResponse>(SHOPS_API_URL.adminPendingShops, {
      params: convertPaginationRequest(paginatedRequest)
    })
  }

  getAdminIgnoredShops(paginatedRequest: IPaginationRequest): Observable<IAdminShopsResponse> {
    return this.http.get<IAdminShopsResponse>(SHOPS_API_URL.adminIgnoredShops, {
      params: convertPaginationRequest(paginatedRequest)
    })
  }

  adminVerifyShop(shopId: string) {
    return this.http.post(SHOPS_API_URL.adminVerifyShop(shopId), {})
  }

  adminDeclineShop(shopId: string) {
    return this.http.post(SHOPS_API_URL.adminDeclineShop(shopId), {})
  }

  adminDeleteShop(shopId: string) {
    return this.http.delete(SHOPS_API_URL.adminDeleteShop(shopId))
  }

  adminDeleteShopUser(shopId: string, userId: string) {
    return this.http.delete(SHOPS_API_URL.adminDeleteShopUser(shopId, userId))
  }

  getDailyReportsShops(payload: Date, excludeBatch: boolean): Observable<IAdminShopsResponse> {
    return this.http.get<IAdminShopsResponse>(
      `${
        SHOPS_API_URL.adminDailyReportsShops
      }?excludeBatch=${!!excludeBatch}&date=${this.dateService.convertToDateString(payload)}`
    )
  }
}
