import { Injectable } from '@angular/core'
import { OrganizationFeatures, UserAccountState } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  constructor(private store: Store) {}

  checkHasPermission(feature: OrganizationFeatures) {
    const currentUser = this.store.selectSnapshot(UserAccountState.currentUser)
    return (
      currentUser &&
      currentUser.organizationFeaturePermissions &&
      currentUser.organizationFeaturePermissions.includes(feature)
    )
  }
}
