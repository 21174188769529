<div
  class="menu-container w-full h-[70px] fixed top-0 left-0 z-[1000] lg:sticky lg:flex lg:flex-col lg:h-screen text-white"
  [ngClass]="{ 'lg:w-[260px]': !smallMenu, 'lg:w-[55px]': smallMenu }"
>
  <!-- Header -->
  <div class="flex justify-between bg-black h-[70px]">
    <div class="relative w-full flex flex-col px-[15px] pt-[10px]">
      <img
        class="hidden lg:block absolute right-[3px] top-2xs cursor-pointer"
        src="assets/images/autovitals/{{ !smallMenu ? 'collapse' : 'expand' }}.png"
        alt="Collapse Icon"
        (click)="smallMenu = !smallMenu"
      />
      <div class="flex items-center">
        <img
          class="mr-sm"
          [ngClass]="{ 'w-[33px]': !smallMenu, 'w-[25px]': smallMenu }"
          src="assets/images/autovitals/logo.png"
          alt="Logo"
        />
        <div *ngIf="!smallMenu" class="pt-xs flex flex-col text-[15px] tracking-tightest">
          <span class="leading-sm">The Digital Shop</span>
          <span class="font-bold">Business Control Panel</span>
        </div>
      </div>
      <div *ngIf="!smallMenu" class="mt-[3px] flex items-center text-[#cccccc] text-[10px]">
        <a class="underline" href="https://help.autovitals.com/product-news" target="_blank">V6.0.117</a>
        <span>&nbsp;&nbsp; © 2023 AutoVitals, Inc. (</span>
        <a
          class="underline"
          href="https://shop.autovitals.com/Public/Terms.aspx"
          target="_blank"
          title="Terms of Service"
        >
          Terms
        </a>
        <span>)</span>
      </div>
    </div>

    <button
      (click)="showMenu = !showMenu"
      type="button"
      class="lg:hidden mt-sm mr-lg h-[34px] border border-gray-100 border-opacity-20 rounded-sm p-[10px]"
    >
      <span class="autovitals-icon-bar"></span>
      <span class="autovitals-icon-bar"></span>
      <span class="autovitals-icon-bar"></span>
    </button>
  </div>

  <!-- Menu List -->
  <div
    class="menu-list lg:pb-2xl lg:flex-1 lg:h-auto lg:overflow-auto overflow-hidden"
    [ngClass]="{ 'h-auto': showMenu, 'h-0': !showMenu, 'small-menu-list': smallMenu }"
  >
    <a class="menu-item" href="https://tcp.autovitals.com/?sid=12058">
      <img src="assets/images/autovitals/home.svg" alt="Home Icon" />
      <span>Home</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/Settings?sid=12058">
      <img src="assets/images/autovitals/settings.svg" alt="Settings Icon" />
      <span>Settings</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/Primary?sid=12058">
      <img src="assets/images/autovitals/primary.svg" alt="Chart Icon" />
      <span>Primary</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/MultiShop?sid=12058">
      <img src="assets/images/autovitals/primary.svg" alt="Chart Icon" />
      <span>MultiShop</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/MultiShopChart?sid=12058">
      <img src="assets/images/autovitals/primary.svg" alt="Chart Icon" />
      <span>MultiShop Chart</span>
    </a>
    <a class="menu-item menu-item-active">
      <img src="assets/images/autovitals/mg-logo.svg" alt="MG Icon" />
      <span>Market Genie</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/Insights?sid=12058">
      <img src="assets/images/autovitals/insights.svg" alt="Insights Icon" />
      <span>Insights</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/InsightsBeta?sid=12058">
      <img src="assets/images/autovitals/insights.svg" alt="Insights Icon" />
      <span>Insights Beta</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/Meetings?sid=12058">
      <img src="assets/images/autovitals/trainer.svg" alt="Trainer Icon" />
      <span>Next Meeting</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/Help?sid=12058">
      <img src="assets/images/autovitals/help.svg" alt="Help Icon" />
      <span>Help Center</span>
    </a>
    <a class="menu-item" href="https://tcp.autovitals.com/Tablets?sid=12058">
      <img src="assets/images/autovitals/settings.svg" alt="Settings Icon" />
      <span>Tablets</span>
    </a>
    <!-- <a class="menu-item">
      <img src="assets/images/autovitals/sign-out.svg" alt="Sign-out Icon" />
      <span>Sign out</span>
    </a> -->
  </div>
</div>
