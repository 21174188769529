import { environment } from '@mg-platform/core/core-util'

export const SHOPS_API_URL = {
  base: (shopId?: string) => `${environment.apiUrl}/shops${shopId ? '/' + shopId : ''}`,
  getOneShop: (shopId: string, withLastReportDate?: boolean) =>
    `${environment.apiUrl}/shops/${shopId}${withLastReportDate ? '/' + withLastReportDate : ''}`,
  setShopFocus: (shopId?: string) => `${environment.apiUrl}/shops/focus/${shopId}`,

  // Shop Users
  getShopUsers: (shopId?: string) => `${environment.apiUrl}/shops/${shopId}/shopusers`,
  getShopPendingUsers: (shopId?: string) => `${environment.apiUrl}/shops/${shopId}/shoppendingusers`,
  deleteShopUser: (shopId: string, userId: string) => `${environment.apiUrl}/shops/${shopId}/${userId}`,

  // Shop Groups
  updateShopGroup: `${environment.apiUrl}/groups`,
  deleteShopGroup: (groupId: string) => `${environment.apiUrl}/groups/${groupId}`,
  autoCreateShopGroups: `${environment.apiUrl}/groups/auto-create`,

  // Admin Shops
  adminShops: (shopId?: string) => `${environment.apiUrl}/admin/shops${shopId ? '/' + shopId : ''}`,
  adminPendingShops: `${environment.apiUrl}/admin/shops/pendingverification`,
  adminIgnoredShops: `${environment.apiUrl}/admin/shops/ignored`,
  adminDeclineShop: (shopId: string) => `${environment.apiUrl}/admin/shops/${shopId}/decline`,
  adminVerifyShop: (shopId: string) => `${environment.apiUrl}/admin/shops/${shopId}/verify`,
  adminDeleteShop: (shopId: string) => `${environment.apiUrl}/admin/shops/${shopId}`,
  adminDeleteShopUser: (shopId: string, userId: string) =>
    `${environment.apiUrl}/admin/shops/${shopId}/${userId}`,
  adminDailyReportsShops: `${environment.apiUrl}/admin/shops/dailyreportshops`
}
