import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { MainLayoutComponent } from './main-layout/main-layout.component'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { CoreFeatureModule } from '@mg-platform/core/core-feature'
import { AutovitalsMenuComponent } from './autovitals-menu/autovitals-menu.component'
import { PublicReportsLayoutComponent } from './public-reports-layout/public-reports-layout.component'

@NgModule({
  declarations: [MainLayoutComponent, AutovitalsMenuComponent, PublicReportsLayoutComponent],
  imports: [CommonModule, RouterModule, SvgIconsModule, MatMenuModule, MatIconModule, CoreFeatureModule],
  exports: [MainLayoutComponent, PublicReportsLayoutComponent]
})
export class MainLayoutModule {}
