<div class="side-menu-container">
  <div class="grow-0 shrink-0 p-sm mx-base mt-base mb-2xl cursor-pointer" routerLink="/dashboard">
    <img src="assets/images/icons/logo-full.svg" class="h-[40px]" alt="Logo" />
  </div>

  <div class="menu-items-container">
    <div class="w-full">
      <ng-container *ngFor="let item of mainMenuRoutes">
        <div
          *ngIf="!item.hidden"
          class="menu-item"
          [routerLink]="item.path"
          routerLinkActive="menu-item-selected"
          [routerLinkActiveOptions]="{ exact: !!item.exact }"
        >
          <div class="menu-icon">
            <svg-icon [key]="item.icon" class="!text-lg"></svg-icon>
          </div>
          <div class="menu-item-label">
            <div class="pl-sm">{{ item.label }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
