import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-week-selector-dialog',
  templateUrl: './week-selector-dialog.component.html',
  styleUrls: ['./week-selector-dialog.component.scss']
})
export class WeekSelectorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<WeekSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { availableWeeks: ISelectItem[]; selectedWeekNumber: number }
  ) {}
}
