export enum BenchmarkType {
  singleDay = 'singleDay',
  weekToDate = 'weekToDate',
  monthToDate = 'monthToDate',
  yearToDate = 'yearToDate',
  custom = 'custom',
  fourteenDays = 'fourteenDays',
  lastWeek = 'lastWeek',
  lastTwoWeeks = 'lastTwoWeeks'
}

export const BenchmarkTypeToLabel: { [key: string]: string } = {
  singleDay: 'Single Day',
  weekToDate: 'Week to date',
  monthToDate: 'Month to date',
  yearToDate: 'Year to date',
  custom: 'Custom',
  fourteenDays: 'Last 14 days',
  lastWeek: 'Last week',
  lastTwoWeeks: 'Last 2 weeks'
}
