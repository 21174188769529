<div class="modal-container">
  <div class="text-base leading-base font-medium">Select shop focus</div>
  <div class="mt-2xs text-sm leading-sm">Select the product mix that best describes your shop.</div>

  <form class="flex-1 flex flex-col mt-xl w-full" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="flex-1 flex flex-col">
      <div class="mt-lg first:mt-0" *ngFor="let item of shopFocusItems">
        <mg-radio [item]="item" controlName="focusType"></mg-radio>
      </div>
    </div>

    <div class="hidden md:flex mt-xl w-full flex-row items-center justify-end">
      <mg-button class="mr-2xl" size="small-shrink" variant="ghost" (click)="dialogRef.close()">
        Cancel
      </mg-button>
      <mg-button
        type="submit"
        size="small-shrink"
        variant="ghost"
        [loading]="isProcessing"
        [disabled]="form.invalid"
        extraBtnClass="{{ isProcessing ? 'bg-contrast' : '' }}"
      >
        Save
      </mg-button>
    </div>

    <div class="mt-xl md:hidden flex flex-col">
      <mg-button
        class="mb-base flex-1"
        type="submit"
        size="small"
        [loading]="isProcessing"
        [disabled]="form.invalid"
      >
        Save
      </mg-button>
      <mg-button class="flex-1" size="small" variant="secondary" (click)="dialogRef.close()">
        Cancel
      </mg-button>
    </div>
  </form>
</div>
