export const UserAccountRoutes = {
  Signup: 'signup',
  Company: 'signup/company',
  Login: 'login',
  ResetPassword: 'reset-password/:token',
  RequestPasswordReset: 'request-password-reset'
}

export const UserAccountPaths = {
  SignupPath: `/${UserAccountRoutes.Signup}`,
  CompanyPath: `/${UserAccountRoutes.Company}`,
  LoginPath: `/${UserAccountRoutes.Login}`,
  ResetPasswordPath: (token: string) => `/${UserAccountRoutes.ResetPassword.replace(':token', token)}`,
  RequestPasswordResetPath: `/${UserAccountRoutes.RequestPasswordReset}`
}
