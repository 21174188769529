<div class="bg-contrast h-full w-full relative p-lg overflow-auto">
  <!-- Close Btn -->
  <div class="absolute flex cursor-pointer top-lg right-2xl text-txt-secondary">
    <svg-icon key="close" class="!text-base" (click)="dialogRef.close()"></svg-icon>
  </div>

  <div class="mt-2xl group">
    <ng-container *ngFor="let item of data.routes">
      <div
        *ngIf="!item.hidden"
        class="menu-item"
        [routerLink]="item.path"
        routerLinkActive="!bg-ui-selected !text-white"
        [routerLinkActiveOptions]="{ exact: !!item.exact }"
        (click)="closeDialog()"
      >
        <div class="menu-icon">
          <svg-icon [key]="item.icon" class="!text-lg"></svg-icon>
        </div>
        <div class="menu-item-label">
          <div class="pl-sm">{{ item.label }}</div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="data.showLogout" class="menu-item cursor-pointer" (click)="logout()">
      <div class="menu-icon">
        <svg-icon key="logout" class="!text-lg"></svg-icon>
      </div>
      <div class="menu-item-label">
        <div class="pl-sm">Log Out</div>
      </div>
    </div>
  </div>
</div>
