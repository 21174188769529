import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router'
import { LOCALSTORAGE_KEYS } from '@mg-platform/core/core-util'
import { IUser, Logout, Role, UserAccountState } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { map, Observable, take } from 'rxjs'
import { ApplicationRoutes } from '../../app-routes-names'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root'
})
export class OnlyUserGuard {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  constructor(private router: Router, private store: Store, private toastrService: ToastrService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        if (!user?.role) {
          return false
        }
        switch (user.role) {
          case Role.admin:
            if (localStorage.getItem(LOCALSTORAGE_KEYS.JWT_TOKEN)) {
              localStorage.removeItem(LOCALSTORAGE_KEYS.JWT_TOKEN)
            }
            this.store.dispatch(new Logout())
            this.toastrService.error('You can not use User Panel with admin account!')
            this.router.navigateByUrl('/login')
            return false

          case Role.enterpriseuser:
            this.router.navigateByUrl(ApplicationRoutes.enterprisePanel)
            return false
        }

        return true
      })
    )
  }
}
