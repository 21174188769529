import { IPaginationRequest } from '@mg-platform/core/core-data-access'
import { IUpdateAdminUserRequest } from '../../interfaces/admin-users.interface'

export class GetAllAdminUsers {
  static readonly type = '[AdminUsers] Get All Admin Users'
  constructor(public paginatedRequest: IPaginationRequest) {}
}

export class GetOneAdminUser {
  static readonly type = '[AdminUsers] Get One Admin User'
  constructor(public userId: string) {}
}

export class UpdateAdminUser {
  static readonly type = '[AdminUsers] Update Admin User'
  constructor(public userId: string, public payload: IUpdateAdminUserRequest) {}
}
