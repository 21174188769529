import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import { GetCalendarBenchmark, GetShopBenchmark, ResetBenchmark } from './benchmark-reports.actions'
import { BenchmarkReportsStateModel } from './benchmark-reports.model'

@State<BenchmarkReportsStateModel>({
  name: 'benchmarkReports'
})
@Injectable()
export class BenchmarkReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static benchmark(state: BenchmarkReportsStateModel) {
    return state.benchmark
  }

  @Selector()
  static calendarBenchmark(state: BenchmarkReportsStateModel) {
    return state.calendarBenchmark ?? []
  }

  @Action(GetShopBenchmark)
  getShopBenchmark(ctx: StateContext<BenchmarkReportsStateModel>, { payload }: GetShopBenchmark) {
    return this.reportsService.benchmarks(payload).pipe(
      tap((res) => {
        ctx.patchState({ benchmark: res })
      })
    )
  }

  @Action(GetCalendarBenchmark)
  getCalendarBenchmark(ctx: StateContext<BenchmarkReportsStateModel>, { shopId }: GetCalendarBenchmark) {
    return this.reportsService.calendar(shopId).pipe(
      tap((res) => {
        ctx.patchState({ calendarBenchmark: res })
      })
    )
  }

  @Action(ResetBenchmark)
  resetBenchmark(ctx: StateContext<BenchmarkReportsStateModel>) {
    ctx.patchState({ benchmark: undefined })
  }
}
