import { ErrorHandler, Injectable } from '@angular/core'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any) {
    if (error?.message?.includes('Loading chunk') && error?.message?.includes('failed')) {
      window.location.reload()
    }

    if (error) {
      console.error(error)
    }
  }
}
