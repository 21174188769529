import { Injectable } from '@angular/core'
import { HttpService } from '../../services/http.service'
import { Observable } from 'rxjs'
import { IStatesResponse } from '../../interfaces/state.interface'
import { environment } from '@mg-platform/core/core-util'

@Injectable({
  providedIn: 'root'
})
export class StatesService {
  constructor(private http: HttpService) {}

  getStates(): Observable<IStatesResponse> {
    return this.http.get<IStatesResponse>(`${environment.apiUrl}/states`)
  }
}
