import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { GetStates } from './states.actions'
import { StatesStateModel } from './states.model'
import { StatesService } from './states.service'
import { uniqBy } from 'lodash'
import { CountryCode } from '../../enums/country-code.enum'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { SetFilters } from '../filters/filters.actions'

@State<StatesStateModel>({
  name: 'states'
})
@Injectable()
export class StatesState {
  constructor(private statesService: StatesService, private store: Store) {}

  @Selector()
  static userCountry(state: StatesStateModel) {
    return state.userCountry
  }

  @Selector()
  static regions(state: StatesStateModel) {
    return state.regions
  }

  @Selector()
  static states(state: StatesStateModel) {
    return state.states
  }

  @Action(GetStates)
  GetStates(ctx: StateContext<StatesStateModel>) {
    return this.statesService.getStates().pipe(
      tap((res) => {
        const states = res.states ?? []
        const regions = uniqBy(states, (x) => x.regionId)
        const allCountries: ISelectItem[] = uniqBy(states, (x) => x.countryId).map((el) => ({
          label: el.countryName,
          value: el.countryId,
          extraInfo: el.countryCode
        }))
        let userCountry = CountryCode.ALL
        if (!allCountries.find((el) => el.extraInfo === CountryCode.USA)) {
          userCountry = CountryCode.CA
        } else if (!allCountries.find((el) => el.extraInfo === CountryCode.CA)) {
          userCountry = CountryCode.USA
        }
        this.store.dispatch(
          new SetFilters({
            allCountries
          })
        )
        ctx.patchState({ states, regions, userCountry })
      })
    )
  }
}
