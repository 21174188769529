import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { ORGANIZATION_AVERAGE } from '../constants/global.constant'
import { formatNumber } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  generateRandomString() {
    return (Math.random() + 1).toString(36).substring(2)
  }

  kiloMilionFormatter(value: number) {
    if (value >= 1e6) {
      return formatNumber(value / 1e6, this.locale, '1.0-1') + 'M'
    }
    if (value >= 1e3) {
      return formatNumber(value / 1e3, this.locale, '1.0-1') + 'K'
    }
    return value
  }

  prepareBaseRequest(shopId?: string, groupId?: string) {
    if (shopId) {
      return shopId === ORGANIZATION_AVERAGE.id
        ? {
            organizationAverage: true
          }
        : {
            shops: [shopId]
          }
    }

    if (groupId) {
      return {
        groups: [groupId]
      }
    }

    return {}
  }
}
