import { IPaginationRequest } from '@mg-platform/core/core-data-access'
import { IAdminUpdateShopRequest } from '../../interfaces/update-shop.interface'
import { PosType } from '@mg-platform/admin-reports/admin-reports-data-access'

export class GetAdminShops {
  static readonly type = '[AdminShops] Get All Verified Shops'
  constructor(
    public paginatedRequest: IPaginationRequest,
    public type: 'verified' | 'pending' | 'ignored'
  ) {}
}

export class GetOneAdminShop {
  static readonly type = '[AdminShops] Get One Admin Shop'
  constructor(public shopId: string) {}
}

export class SetAdminShopPosType {
  static readonly type = '[AdminShops] Set Admin Shop Pos Type'
  constructor(public posType: PosType) {}
}

export class UpdateAdminShop {
  static readonly type = '[AdminShops] Update Admin Shop'
  constructor(public payload: IAdminUpdateShopRequest, public shopId: string) {}
}

export class AdminVerifyShop {
  static readonly type = '[AdminShops] Admin Verify Shop'
  constructor(public shopId: string) {}
}

export class AdminDeclineShop {
  static readonly type = '[AdminShops] Admin Decline Shop'
  constructor(public shopId: string) {}
}

export class AdminDeleteShop {
  static readonly type = '[AdminShops] Admin Delete Shop'
  constructor(public shopId: string) {}
}

export class AdminDeleteShopUser {
  static readonly type = '[AdminShops] Admin Delete Shop User'
  constructor(public shopId: string, public userId: string) {}
}

export class GetAdminDailyReportsShops {
  static readonly type = '[AdminShops] Get Admin Daily Reports Shops'
  constructor(public payload: Date, public excludeBatch: boolean) {}
}
