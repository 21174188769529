import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IShop } from '@mg-platform/shops/shops-data-access'
import { PillSize } from '../pill/pill.component'
import { sortBy } from 'lodash'

@UntilDestroy()
@Component({
  selector: 'mg-included-shops',
  templateUrl: './included-shops.component.html',
  styleUrls: ['./included-shops.component.scss']
})
export class IncludedShopsComponent implements OnInit {
  @Input() shopsList: IShop[] = []
  @Input() pillSize: PillSize = 's'

  @Output() canceld = new EventEmitter()

  ngOnInit(): void {
    this.shopsList = sortBy(this.shopsList, (x) => x.displayName)
  }
}
