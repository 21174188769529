import { Injectable } from '@angular/core'
import { IRangeDateValue } from '@mg-platform/core/core-feature'
import moment, { Moment } from 'moment'

@Injectable({
  providedIn: 'root'
})
export class DateService {
  convertToDateString(date: Date | undefined) {
    if (!date) {
      return
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  momentToDateString(date: string | Date | Moment | undefined) {
    if (!date) {
      return
    }
    return moment(date).utc().format().split('T')[0]
  }

  momentCalculatedFormattedPeriod(rangeDate: IRangeDateValue | undefined, withoutYear = false) {
    let formattedPeriodTime = ''
    if (!rangeDate || !rangeDate?.from || !rangeDate?.to) {
      return
    }

    const { from, to } = {
      from: moment(rangeDate.from).utc(),
      to: moment(rangeDate.to).utc()
    }
    formattedPeriodTime +=
      from.isSame(to, 'year') || withoutYear ? from.format('MMM D') : from.format('MMM D, YYYY')
    formattedPeriodTime += ` - ${withoutYear ? to.format('MMM D') : to.format('MMM D, YYYY')}`

    return formattedPeriodTime
  }
}
