import { ICompareWithSelection } from '@mg-platform/core/core-data-access'

export class GetCompareReports {
  static readonly type = '[CompareReports] Get Compare Reports'
  constructor(public payload: Partial<ICompareWithSelection>) {}
}

export class GetCompareAllShopsReports {
  static readonly type = '[CompareReports] Get Compare All Shops Reports'
  constructor(public payload: Partial<ICompareWithSelection>) {}
}
