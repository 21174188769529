import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import {
  GetOneDailyReport,
  AddDailyReport,
  GetMissingDaysReports,
  UpdateDailyReport,
  DailyReportStatusChanged
} from './daily-reports.actions'
import { DailyReportsStateModel } from './daily-reports.model'

@State<DailyReportsStateModel>({
  name: 'dailyReports'
})
@Injectable()
export class DailyReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static dailyReport(state: DailyReportsStateModel) {
    return state.dailyReport
  }

  @Selector()
  static missingDays(state: DailyReportsStateModel) {
    return state.missingDays
  }

  @Action(GetOneDailyReport)
  getOneDailyReport(
    ctx: StateContext<DailyReportsStateModel>,
    { shopId, dailyReportId }: GetOneDailyReport
  ) {
    return this.reportsService.getOneDailyReport(shopId, dailyReportId).pipe(
      tap((res) => {
        ctx.patchState({ dailyReport: res })
      })
    )
  }

  @Action(GetMissingDaysReports)
  getMissingDaysReports(ctx: StateContext<DailyReportsStateModel>) {
    return this.reportsService.getMissingDaysReports().pipe(
      tap((res) => {
        ctx.patchState({ missingDays: res })
      })
    )
  }

  @Action(AddDailyReport)
  addDailyReport(ctx: StateContext<DailyReportsStateModel>, { payload }: AddDailyReport) {
    return this.reportsService.addDailyReport(payload)
  }

  @Action(UpdateDailyReport)
  updateDailyReport(ctx: StateContext<DailyReportsStateModel>, { payload, shopId }: UpdateDailyReport) {
    return this.reportsService.updateDailyReport(payload, shopId)
  }

  @Action(DailyReportStatusChanged)
  dailyReportStatusChanged(
    ctx: StateContext<DailyReportsStateModel>,
    { payload }: DailyReportStatusChanged
  ) {
    ctx.patchState({ dailyReportChanged: payload })
  }
}
