import { ApplicationRoutes } from '../../../app-routes-names'

export type GlobalTargetsSection = 'salesPerDay' | 'carsPerDay' | 'avgTicket'

export const ShopsRoutes = {
  allShops: 'all',
  editShop: ':shopId',
  globalTargets: 'global-targets',
  groups: 'groups',
  addGroup: 'edit-group'
}

const { shops } = ApplicationRoutes

export const ShopsPaths = {
  allShops: `/${shops}/${ShopsRoutes.allShops}`,
  editShop: (shopId: string) => `/${shops}/${ShopsRoutes.editShop.replace(':shopId', shopId)}`,
  globalTargets: `/${shops}/${ShopsRoutes.globalTargets}`,
  groups: `/${shops}/${ShopsRoutes.groups}`,
  addGroup: `/${shops}/${ShopsRoutes.addGroup}`
}
