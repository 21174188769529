import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { NotificationsService } from '../../notifications.service'
import { UserNotificationsStateModel } from './user-notifications.model'
import { GetUserNotificationsConfig, UpdateUserNotificationsConfig } from './user-notifications.actions'

@State<UserNotificationsStateModel>({
  name: 'userNotifications'
})
@Injectable()
export class UserNotificationsState {
  constructor(private notificationsService: NotificationsService) {}

  @Selector()
  static notifications(state: UserNotificationsStateModel) {
    return state.notifications
  }

  @Action(GetUserNotificationsConfig)
  getUserNotificationsConfig(ctx: StateContext<UserNotificationsStateModel>) {
    return this.notificationsService.getUserNotificationsConfig().pipe(
      tap((res) => {
        ctx.patchState({
          notifications: res?.items ?? []
        })
      })
    )
  }

  @Action(UpdateUserNotificationsConfig)
  updateUserNotificationsConfig(
    ctx: StateContext<UserNotificationsStateModel>,
    { payload }: UpdateUserNotificationsConfig
  ) {
    return this.notificationsService.updateUserNotificationsConfig(payload)
  }
}
