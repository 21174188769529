import { Injectable } from '@angular/core'
import { ShopsService } from '../../shops.service'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ShopUsersStateModel } from './shop-users.model'
import { DeleteShopUser, GetShopPendingUsers, GetShopUsers } from './shop-users.actions'

@State<ShopUsersStateModel>({
  name: 'shopUsers'
})
@Injectable()
export class ShopUsersState {
  constructor(private shopsService: ShopsService) {}

  @Selector()
  static users(state: ShopUsersStateModel) {
    return state.users
  }

  @Action(GetShopUsers)
  getShopUsers(ctx: StateContext<ShopUsersStateModel>, { shopId }: GetShopUsers) {
    return this.shopsService.getShopUsers(shopId).pipe(
      tap((res) => {
        ctx.patchState({ users: res?.users })
      })
    )
  }

  @Action(GetShopPendingUsers)
  getShopPendingUsers(ctx: StateContext<ShopUsersStateModel>, { shopId }: GetShopPendingUsers) {
    return this.shopsService.getShopPendingUsers(shopId).pipe(
      tap((res) => {
        ctx.patchState({ users: res?.users })
      })
    )
  }

  @Action(DeleteShopUser)
  deleteShopUser(ctx: StateContext<ShopUsersStateModel>, { shopId, userId }: DeleteShopUser) {
    return this.shopsService.deleteShopUser(shopId, userId)
  }
}
