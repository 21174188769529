import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import { ComparatorReportsStateModel } from './compare-reports.model'
import { ICompareResponse } from '../../interfaces/compare-report.interface'
import { GetCompareAllShopsReports, GetCompareReports } from './compare-reports.actions'

@State<ComparatorReportsStateModel>({
  name: 'comparatorReports'
})
@Injectable()
export class ComparatorReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static reports(state: ComparatorReportsStateModel): ICompareResponse {
    return state.reports
  }

  @Action(GetCompareReports)
  getCompareReports(ctx: StateContext<ComparatorReportsStateModel>, { payload }: GetCompareReports) {
    return this.reportsService.getCompareReports(payload).pipe(
      tap((res) => {
        ctx.patchState({ reports: res })
      })
    )
  }

  @Action(GetCompareAllShopsReports)
  getCompareAllShopsReports(
    ctx: StateContext<ComparatorReportsStateModel>,
    { payload }: GetCompareAllShopsReports
  ) {
    return this.reportsService.getCompareAllShopsReports(payload).pipe(
      tap((res) => {
        ctx.patchState({ reports: res })
      })
    )
  }
}
