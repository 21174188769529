import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router'
import { environment } from '@mg-platform/core/core-util'
import { IUser, Role, UserAccountState } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { map, Observable, take } from 'rxjs'
import { ApplicationRoutes } from '../../app-routes-names'

@Injectable({
  providedIn: 'root'
})
export class OnlyEnterpriseUserGuard {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        if (!user?.role) {
          return false
        }
        switch (user.role) {
          case Role.admin:
            document.location.href = environment.adminUrl
            return false

          case Role.user:
            this.router.navigateByUrl(ApplicationRoutes.dashboard)
            return false
        }

        return true
      })
    )
  }
}
