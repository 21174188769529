<div class="pb-2xl" *ngIf="currentUser$ | async as currentUser">
  <mg-page-title
    title="My Profile"
    [subTitle]="'Hello ' + currentUser.firstName + ' ' + currentUser.lastName"
  ></mg-page-title>

  <mg-tabs
    [sections]="sections"
    (selectedSectionChanged)="selectedRoute = $event; router.navigateByUrl(selectedRoute)"
    [selectedSection]="selectedRoute"
  ></mg-tabs>
  <div class="mt-2xl">
    <router-outlet></router-outlet>
  </div>
</div>
