import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { EnterpriseReportsService } from '../../enterprise-reports.service'
import { GetEnterpriseTrends, SetEnterpriseTrendFilters } from './enterprise-trends.actions'
import { EnterpriseTrendsStateModel } from './enterprise-trends.model'

@State<EnterpriseTrendsStateModel>({
  name: 'enterpriseTrends'
})
@Injectable()
export class EnterpriseTrendsState {
  constructor(private enterpriseReportsService: EnterpriseReportsService) {}

  @Selector()
  static trends(state: EnterpriseTrendsStateModel) {
    return state.trends
  }

  @Selector()
  static trendFilters(state: EnterpriseTrendsStateModel) {
    return state.trendFilters
  }

  @Action(GetEnterpriseTrends)
  getEnterpriseTrends(ctx: StateContext<EnterpriseTrendsStateModel>, { payload }: GetEnterpriseTrends) {
    ctx.patchState({ trends: undefined })
    return this.enterpriseReportsService.getTrends(payload).pipe(
      tap((res) => {
        ctx.patchState({ trends: res })
      })
    )
  }

  @Action(SetEnterpriseTrendFilters)
  setEnterpriseTrendFilters(
    ctx: StateContext<EnterpriseTrendsStateModel>,
    { payload }: SetEnterpriseTrendFilters
  ) {
    ctx.patchState({ trendFilters: payload })
  }
}
