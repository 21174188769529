import {
  IAddDailyReportRequest,
  IUpdateDailyReportRequest
} from '../../interfaces/daily-report.interface'

export class GetOneDailyReport {
  static readonly type = '[DailyReports] Get One Daily Report'
  constructor(public shopId: string, public dailyReportId: string) {}
}

export class AddDailyReport {
  static readonly type = '[DailyReports] Add Daily Report'
  constructor(public payload: IAddDailyReportRequest[]) {}
}

export class UpdateDailyReport {
  static readonly type = '[DailyReports] Update Daily Report'
  constructor(public payload: IUpdateDailyReportRequest, public shopId: string) {}
}

export class GetMissingDaysReports {
  static readonly type = '[DailyReports] Get Missing Days Reports'
}

export class DailyReportStatusChanged {
  static readonly type = '[DailyReports] Daily Report Status Changed'
  constructor(public payload: boolean) {}
}
