import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { EnterpriseReportsService } from '../../enterprise-reports.service'
import { GetEnterpriseBenchmark, SetEnterpriseBenchmarkFilters } from './enterprise-benchmark.actions'
import { EnterpriseBenchmarkStateModel } from './enterprise-benchmark.model'

@State<EnterpriseBenchmarkStateModel>({
  name: 'enterpriseBenchmark'
})
@Injectable()
export class EnterpriseBenchmarkState {
  constructor(private enterpriseReportsService: EnterpriseReportsService) {}

  @Selector()
  static benchmarks(state: EnterpriseBenchmarkStateModel) {
    return state.benchmarks
  }

  @Selector()
  static benchmarksFilters(state: EnterpriseBenchmarkStateModel) {
    return state.benchmarksFilters
  }

  @Action(GetEnterpriseBenchmark)
  getEnterpriseBenchmark(
    ctx: StateContext<EnterpriseBenchmarkStateModel>,
    { payload }: GetEnterpriseBenchmark
  ) {
    return this.enterpriseReportsService.benchmarks(payload).pipe(
      tap((res) => {
        ctx.patchState({ benchmarks: res.data })
      })
    )
  }

  @Action(SetEnterpriseBenchmarkFilters)
  setEnterpriseBenchmarkFilters(
    ctx: StateContext<EnterpriseBenchmarkStateModel>,
    { payload }: SetEnterpriseBenchmarkFilters
  ) {
    ctx.patchState({ benchmarksFilters: payload })
  }
}
