<form class="flex items-center" [formGroup]="form">
  <mg-input
    class="block w-[60px]"
    controlName="hour"
    type="select"
    icon="arrow-down-simple"
    iconSize="10px"
    size="small"
    iconColor="#6B7980"
    [items]="hourItems"
  ></mg-input>

  <div class="mx-sm text-base leading-lg text-txt-secondary">:</div>

  <mg-input
    class="block w-[60px]"
    controlName="minute"
    type="select"
    icon="arrow-down-simple"
    iconSize="10px"
    size="small"
    iconColor="#6B7980"
    [items]="minuteItems"
  ></mg-input>

  <div class="mx-sm text-base leading-lg text-txt-secondary">:</div>

  <mg-input
    class="block w-[60px]"
    controlName="period"
    type="select"
    icon="arrow-down-simple"
    iconSize="10px"
    size="small"
    iconColor="#6B7980"
    [items]="periodItems"
  ></mg-input>
</form>
