import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http'
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, tap, throwError } from 'rxjs'
import { LOCALSTORAGE_KEYS, ShowErrorService } from '@mg-platform/core/core-util'
import { Logout, USERS_API_URL, UsersService } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { CONTACT_API_URL } from '@mg-platform/contact/contact-data-access'
import { REPORTS_API_URL } from '@mg-platform/reports/reports-data-access'
import { Router } from '@angular/router'

interface IAllowedUrl {
  url: string
  methoud: 'get' | 'post' | 'put' | 'delete'
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshing = false
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(
    private usersService: UsersService,
    private store: Store,
    private showErrorService: ShowErrorService,
    private router: Router
  ) {}

  addAuthHeader(request: HttpRequest<any>) {
    const token = localStorage.getItem(LOCALSTORAGE_KEYS.JWT_TOKEN)

    if (!token) {
      return request
    }

    const newRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    })

    return newRequest
  }

  logout() {
    this.store.dispatch(new Logout())
    this.router.navigateByUrl('/login')
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle request
    request = request.clone({
      headers: request.headers.set('Client-Page-Url', window?.location?.href)
    })
    request = this.addAuthHeader(request)

    // Handle response
    return next.handle(request).pipe(
      catchError((error) => {
        if (request.url.includes(USERS_API_URL.refreshToken)) {
          // Logout if refresh api return error for the second time
          this.isRefreshing = false
          this.logout()
          return throwError(() => error)
        }
        if (error.status === 401) {
          const token = localStorage.getItem(LOCALSTORAGE_KEYS.JWT_TOKEN)
          if (!token && request.url.includes(USERS_API_URL.me)) {
            // Throw error if User not login and there is no token
            this.isRefreshing = false
            return throwError(() => error)
          }
          if (!token) {
            // Logout if User is logined in but token removed from localstorage for any reasons
            this.isRefreshing = false
            this.logout()
            return throwError(() => error)
          }
          if (!this.isRefreshing) {
            // Do refreshing token
            this.isRefreshing = true
            this.refreshTokenSubject.next(null)

            return this.usersService
              .refreshToken()
              .pipe(
                tap((res) => {
                  this.isRefreshing = false
                  localStorage.setItem(LOCALSTORAGE_KEYS.JWT_TOKEN, res.token)
                  this.refreshTokenSubject.next(res.token)
                })
              )
              .pipe(switchMap(() => next.handle(this.addAuthHeader(request))))
          }

          // Put the requests in queue
          return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthHeader(request)))
          )
        }

        const allowedUrl: IAllowedUrl[] = [
          { url: USERS_API_URL.me, methoud: 'get' },
          { url: USERS_API_URL.refreshToken, methoud: 'post' },
          { url: CONTACT_API_URL.contact, methoud: 'post' },
          { url: REPORTS_API_URL.addDailyReport, methoud: 'post' },
          // [addDailyReport] used instead of [updateDailyReport] because it needs shopid
          // but the method is [put] so it's for update
          { url: REPORTS_API_URL.addDailyReport, methoud: 'put' } // For [updateDailyReport] url
        ]
        if (
          !allowedUrl.find(
            (el) => request.url.includes(el.url) && request.method.toLowerCase() === el.methoud
          )
        ) {
          this.showErrorService.showHttpError(error)
        }

        return throwError(() => error)
      })
    )
  }
}
