import { Component } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ApplicationRoutes } from '../../../app-routes-names'

@UntilDestroy()
@Component({
  selector: 'mg-public-reports-layout',
  templateUrl: './public-reports-layout.component.html',
  styleUrls: ['./public-reports-layout.component.scss']
})
export class PublicReportsLayoutComponent {
  applicationRoutes = ApplicationRoutes
}
