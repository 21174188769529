import { Injectable } from '@angular/core'
import { HttpService } from '@mg-platform/core/core-data-access'
import { DateService } from '@mg-platform/core/core-util'
import { Observable } from 'rxjs'
import { ENTERPRISE_REPORTS_API_URL } from './enterprise-reports-api-routes'
import {
  IEnterpiseBenchmarkRequest,
  IEnterpiseBenchmarkResponse
} from './interfaces/enterprise-benchmark.interface'
import {
  IEnterpriseMarketPricingsRequest,
  IEnterpriseMarketPricingsResponse
} from './interfaces/enterprise-market-pricings.interface'
import {
  IEnterpriseTrendResponse,
  IEnterpriseTrendsRequest
} from './interfaces/enterprise-trends.interface'

@Injectable({
  providedIn: 'root'
})
export class EnterpriseReportsService {
  constructor(private http: HttpService, private dateService: DateService) {}

  benchmarks(payload: IEnterpiseBenchmarkRequest): Observable<IEnterpiseBenchmarkResponse> {
    const { fromDate, toDate } = payload
    return this.http.post<IEnterpiseBenchmarkResponse>(ENTERPRISE_REPORTS_API_URL.benchmarks, {
      ...payload,
      toDate: this.dateService.convertToDateString(toDate),
      fromDate: this.dateService.convertToDateString(fromDate)
    })
  }

  getTrends(payload: IEnterpriseTrendsRequest): Observable<IEnterpriseTrendResponse> {
    return this.http.post<IEnterpriseTrendResponse>(ENTERPRISE_REPORTS_API_URL.trends, payload)
  }

  getMarketPricings(
    payload: IEnterpriseMarketPricingsRequest
  ): Observable<IEnterpriseMarketPricingsResponse> {
    return this.http.post<IEnterpriseMarketPricingsResponse>(
      ENTERPRISE_REPORTS_API_URL.marketPricings,
      payload
    )
  }
}
