<div class="flex items-center w-full border-b border-gray-300">
  <div
    *ngFor="let item of sections"
    class="flex items-center px-lg py-base cursor-pointer -mb-[1px]"
    [ngClass]="{ 'border-b border-primary': selectedSection === item.key }"
    (click)="selectedSectionChanged.emit(item.key)"
  >
    <div
      *ngIf="item.icon"
      class="flex mr-sm text-txt-secondary"
      [ngClass]="{ '!text-primary': selectedSection === item.key }"
    >
      <svg-icon [key]="item.icon" class="!text-sm"></svg-icon>
    </div>
    <div
      class="text-base leading-base text-txt-secondary"
      [ngClass]="{ '!text-primary': selectedSection === item.key }"
    >
      {{ item.title }}
    </div>
  </div>
</div>
