import { IWeeklyReportRequest } from '../../interfaces/weekly-report.interface'

export class GetWeeklyReportsSales {
  static readonly type = '[WeeklyReports] Get Weekly Reports Sales'
  constructor(public payload: IWeeklyReportRequest) {}
}

export class GetWeeklyReportsCompSales {
  static readonly type = '[WeeklyReports] Get Weekly Reports Comp Sales'
  constructor(public payload: IWeeklyReportRequest) {}
}

export class GetWeeklyReportsMonthlyGoals {
  static readonly type = '[WeeklyReports] Get Weekly Reports Monthly Goals'
  constructor(public payload: IWeeklyReportRequest) {}
}

export class GetWeeklyReportsAverageTrends {
  static readonly type = '[WeeklyReports] Get Weekly Reports Average Trends'
  constructor(public payload: IWeeklyReportRequest) {}
}

export class GetWeeklyReportsPreviousFourthWeeks {
  static readonly type = '[WeeklyReports] Get Weekly Reports Previous Fourth Weeks'
  constructor(public payload: IWeeklyReportRequest) {}
}

export class GetAvailableWeeks {
  static readonly type = '[WeeklyReports] Get Available Weeks'
}

export class GetPublicWeeklyReports {
  static readonly type = '[WeeklyReports] Get Public Weekly Reports'
  constructor(public token: string) {}
}
