import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { IUser, UserAccountState } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { map, Observable, take } from 'rxjs'
import { ApplicationRoutes } from '../../app-routes-names'
import { UserAccountPaths } from '../../features/user-account/user-account-route-names'

@Injectable({
  providedIn: 'root'
})
export class CheckUserShopGuard {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  userAccountPaths = UserAccountPaths
  applicationRoutes = ApplicationRoutes

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const shouldHasShop: boolean = route.data['shouldHasShop']
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        if (shouldHasShop && !user.hasShops) {
          this.router.navigateByUrl(this.userAccountPaths.CompanyPath)
          this.router.navigate([this.userAccountPaths.CompanyPath], {
            queryParams: { returnUrl: state.url }
          })
          return false
        }

        if (!shouldHasShop && user.hasShops) {
          this.router.navigateByUrl(this.applicationRoutes.dashboard)
          return false
        }

        return true
      })
    )
  }
}
