import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsService } from '../../reports.service'
import { GetCompTrendsReport, SetSelectedShopsForCompTrends } from './comp-trends-report.actions'
import { CompTrendsReportStateModel } from './comp-trends-report.model'

@State<CompTrendsReportStateModel>({
  name: 'compTrendsReport'
})
@Injectable()
export class CompTrendsReportState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static trends(state: CompTrendsReportStateModel) {
    return state.trends
  }

  @Selector()
  static selectedShops(state: CompTrendsReportStateModel) {
    return state.selectedShops
  }

  @Action(GetCompTrendsReport)
  getCompTrendsReport(ctx: StateContext<CompTrendsReportStateModel>, { payload }: GetCompTrendsReport) {
    ctx.patchState({ trends: undefined })
    return this.reportsService.getCompTrends(payload).pipe(
      tap((res) => {
        ctx.patchState({ trends: res })
      })
    )
  }

  @Action(SetSelectedShopsForCompTrends)
  setSelectedShopsForCompTrends(
    ctx: StateContext<CompTrendsReportStateModel>,
    { payload }: SetSelectedShopsForCompTrends
  ) {
    ctx.patchState({ selectedShops: payload })
  }
}
