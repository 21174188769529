import {
  IHandleInvitationRequest,
  IInvitedUserStatus,
  IInviteUserRequest
} from '../../interfaces/invitation.interface'

export class SendInvitation {
  static readonly type = '[UserInvitation] Send Invitation'
  constructor(public payload: IInviteUserRequest) {}
}

export class GetInvitationInfo {
  static readonly type = '[UserInvitation] Get Invitation Info'
  constructor(public token: string) {}
}

export class CheckInvitedUser {
  static readonly type = '[UserInvitation] Check Invited User'
  constructor(public token: string) {}
}

export class SetInvitedUserInfo {
  static readonly type = '[UserInvitation] Set Invited User Info'
  constructor(public payload?: IInvitedUserStatus) {}
}

export class AnswerInvitation {
  static readonly type = '[UserInvitation] Answer Invitation'
  constructor(public payload: IHandleInvitationRequest) {}
}

export class DeleteInvitation {
  static readonly type = '[UserInvitation] Delete Invitation'
  constructor(public id: string) {}
}
