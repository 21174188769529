import { Injectable } from '@angular/core'

import { GetStates } from '@mg-platform/core/core-data-access'
import { Store } from '@ngxs/store'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class StatesResolver {
  constructor(private store: Store) {}
  async resolve(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new GetStates()))
  }
}
