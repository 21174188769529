import { ApplicationRoutes } from '../../../app-routes-names'

export const ReportsRoutes = {
  sales: 'sales',
  grossprofit: 'gross-profit'
}

const { reports } = ApplicationRoutes

export const ReportsPaths = {
  sales: `/${reports}/${ReportsRoutes.sales}`,
  grossprofit: `/${reports}/${ReportsRoutes.grossprofit}`
}
