import { Injectable } from '@angular/core'

import { LOCALSTORAGE_KEYS } from '@mg-platform/core/core-util'
import { CheckInvitedUser } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CheckInvitedUserResolver {
  constructor(private store: Store) {}
  async resolve(): Promise<void> {
    const token = localStorage.getItem(LOCALSTORAGE_KEYS.INVITATION_TOKEN)
    if (token) {
      try {
        await firstValueFrom(this.store.dispatch(new CheckInvitedUser(token)))
      } catch (error) {
        return
      }
    }
  }
}
