<img
  *ngIf="
    marketType !== marketTypes.organization &&
    marketType !== marketTypes.group &&
    marketType !== marketTypes.shop &&
    countryCode !== hidenCountry
  "
  class="w-auto h-base"
  src="assets/images/{{ flag }}-new-flag.svg"
/>
