import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DateService, FullScreenModalConfig, MobileMaxWidth } from '@mg-platform/core/core-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { IRangeDateInfo } from '../../interfaces/range-date.interface'
import { RangeDatePickerDialogComponent } from '../range-date-picker-dialog/range-date-picker-dialog.component'
import { cloneDeep } from 'lodash'
import { PeriodType, PeriodTypeToLabel, TodayString } from '@mg-platform/core/core-data-access'

@UntilDestroy()
@Component({
  selector: 'mg-range-date-picker-filter',
  templateUrl: './range-date-picker-filter.component.html',
  styleUrls: ['./range-date-picker-filter.component.scss']
})
export class RangeDatePickerFilterComponent implements OnChanges {
  @Input() value: IRangeDateInfo
  @Input() maxDate: string = TodayString
  @Input() minDate?: string
  @Input() hiddenPeriods: PeriodType[] = []
  @Input() hideAllPeriods = false
  @Input() extraClasses = ''
  @Output() valueChanged = new EventEmitter<IRangeDateInfo>()

  formattedPeriodTime?: string = ''
  selectedValue: IRangeDateInfo
  selectedPeriodType?: PeriodType
  periodTypeToLabel = PeriodTypeToLabel

  window = window
  mobileMaxWidth = MobileMaxWidth

  constructor(private dialogService: MatDialog, private dateService: DateService) {}

  ngOnChanges(): void {
    this.setSelectedDate()
  }

  setSelectedDate() {
    this.selectedValue = cloneDeep(this.value)
    this.selectedPeriodType = this.selectedValue?.periodType
    setTimeout(() => {
      this.formattedPeriodTime = this.dateService.momentCalculatedFormattedPeriod(this.value?.rangeDate)
    })
  }

  openRangeDatePickerDialog() {
    this.dialogService
      .open(RangeDatePickerDialogComponent, {
        ...FullScreenModalConfig,
        data: {
          value: this.selectedValue,
          maxDate: this.maxDate,
          hiddenPeriods: this.hiddenPeriods,
          hideAllPeriods: this.hideAllPeriods
        }
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.valueChanged.emit(value)
        }
        this.setSelectedDate()
      })
  }
}
