import { ITrendFilters } from '@mg-platform/reports/reports-data-access'
import { IEnterpriseTrendsRequest } from '../../interfaces/enterprise-trends.interface'

export class GetEnterpriseTrends {
  static readonly type = '[EnterpriseTrends] Get Enterprise Trends'
  constructor(public payload: IEnterpriseTrendsRequest) {}
}

export class SetEnterpriseTrendFilters {
  static readonly type = '[EnterpriseTrends] Set Enterprise Trend Filters'
  constructor(public payload: ITrendFilters) {}
}
