import { IUserRegisterRequest } from '../../interfaces/user-register.interface'
import { IUserLoginAutovitalsRequest, IUserLoginRequest } from '../../interfaces/user-login.interface'
import {
  IRequestPasswordReset,
  IResetPassword,
  IUpdatePasswordRequest
} from '../../interfaces/password.interface'
import { IUpdateProfileRequest } from '../../interfaces/update-profile.interface'
import { IUserPreferences } from '../../interfaces/user-preferences.interface'
import { ReferralType } from '../../enums/referral-type.enum'

export class SetReferralType {
  static readonly type = '[User] Set Referral Type'
  constructor(public payload?: ReferralType) {}
}

export class SetReturnUrl {
  static readonly type = '[User] Set Return Url'
}

export class NavigateToReturnUrl {
  static readonly type = '[User] Navigate To Return Url'
}

export class Login {
  static readonly type = '[User] Login'
  constructor(public payload: IUserLoginRequest) {}
}

export class LoginAutovitals {
  static readonly type = '[User] Login Autovitals'
  constructor(public payload: IUserLoginAutovitalsRequest) {}
}

export class Logout {
  static readonly type = '[User] Logout'
}

export class GetCurrentUser {
  static readonly type = '[User] Get Current User'
}

export class Register {
  static readonly type = '[User] Register'
  constructor(public payload: IUserRegisterRequest) {}
}

export class RequestPasswordReset {
  static readonly type = '[User] Request Password Reset'
  constructor(public payload: IRequestPasswordReset) {}
}

export class ResetPassword {
  static readonly type = '[User] Reset Password'
  constructor(public payload: IResetPassword) {}
}

export class UpdateProfile {
  static readonly type = '[User] Update Profile'
  constructor(public payload: IUpdateProfileRequest) {}
}

export class UpdatePassword {
  static readonly type = '[User] Update Password'
  constructor(public payload: IUpdatePasswordRequest) {}
}

export class GetUserPreferences {
  static readonly type = '[User] Get User Preferences'
}

export class UpdateUserPreferences {
  static readonly type = '[User] Update User Preferences'
  constructor(public payload: IUserPreferences) {}
}

export class RefreshToken {
  static readonly type = '[User] Refresh Token'
}
